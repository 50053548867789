import React from "react";

function StepDicesSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="22"
        height="22"
        x="7.936"
        y="1"
        fill="url(#paint0_linear)"
        rx="2.087"
        transform="rotate(10.306 7.936 1)"
      ></rect>
      <g filter="url(#filter0_bi)">
        <rect
          width="22"
          height="22"
          x="2"
          y="8"
          fill="#fff"
          fillOpacity="0.6"
          rx="3"
        ></rect>
      </g>
      <rect
        width="4"
        height="4"
        x="5"
        y="15"
        fill="#fff"
        rx="2"
        transform="rotate(-90 5 15)"
      ></rect>
      <rect
        width="4"
        height="4"
        x="5"
        y="27"
        fill="#fff"
        rx="2"
        transform="rotate(-90 5 27)"
      ></rect>
      <rect
        width="4"
        height="4"
        x="11"
        y="21"
        fill="#fff"
        rx="2"
        transform="rotate(-90 11 21)"
      ></rect>
      <rect
        width="4"
        height="4"
        x="17"
        y="15"
        fill="#fff"
        rx="2"
        transform="rotate(-90 17 15)"
      ></rect>
      <rect
        width="4"
        height="4"
        x="17"
        y="27"
        fill="#fff"
        rx="2"
        transform="rotate(-90 17 27)"
      ></rect>
      <defs>
        <filter
          id="filter0_bi"
          width="37.099"
          height="37.099"
          x="-5.549"
          y="0.451"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="3.775"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="5.322"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="13.349"
          x2="34.369"
          y1="1"
          y2="11.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FABF41"></stop>
          <stop offset="1" stopColor="#FF741A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default StepDicesSvg;
