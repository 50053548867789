import * as React from "react";

function ChatIconSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 19.804L10.087 16H18a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h2v3.804zM9.513 14L6 16.196V14H2V2h16v12H9.513zM5 11V9h7v2H5zm0-6v2h9V5H5z"
        fill="#fff"
      />
    </svg>
  );
}

export default ChatIconSvg;
