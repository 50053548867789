import React from "react";
import avatars from "../model/avatars";
import Flag from "../components/Flag/Flag";

const BetInfoTableAvatar = (props) => {
  return (
    <div className="all-bets-td-holder">
      <div className="all-bets-tr-avatar">
        {props.avatar && <img src={avatars[props.avatar]} alt="" />}
      </div>
      <span className="all-bets-tr-username">{props.username}</span>
      <div className="all-bets-tr-flag">
        {props.flagCode === "XS" ? (
          <Flag className="xs-flag" code={"RU"} height="10" width="14" />
        ) : (
          <Flag code={props.flagCode} height="10" width="14" />
        )}
      </div>
    </div>
  );
};

export default BetInfoTableAvatar;
