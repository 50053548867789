import React from "react";

function ChatEmoteSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm4.91-5.552l-1.635-1.15A3.994 3.994 0 0112 16c-1.32 0-2.53-.642-3.275-1.702l-1.636 1.15A5.993 5.993 0 0012 18a5.993 5.993 0 004.91-2.552zM14 11V9h2v2h-2zM8 9v2h2V9H8z"
        clipRule="evenodd"
        opacity="1"
      ></path>
    </svg>
  );
}

export default ChatEmoteSvg;
