import rocketmanTransport from "../RocketmanTransport";
import storage from "../Storage/Storage";

const USERNAME_CONFIG = '/username-config.json';
const MAX_RETRY_COUNT = 10;

let retryCount = 0;

const getLauncherPlatformId = async () => {
  return new Promise ((res, rej) => {
    var checkIsLoggedInIntervl = setInterval(() => {
      retryCount++;
      if (rocketmanTransport.loggedIn || retryCount >= MAX_RETRY_COUNT) {
        clearInterval(checkIsLoggedInIntervl);
        res(rocketmanTransport.platformId)
      }
    },1000);
  });
}

export const setupUserNamePrompt = async () => {

  const platformId =  await getLauncherPlatformId();
  
  if (platformId) {
    const usernameConfig =  await (await fetch(USERNAME_CONFIG)).json();
    const platformIds = usernameConfig?.platformIds;

    if (platformIds && platformIds.length) {
      if (platformIds.includes(Number(platformId))) {
        storage.set('showUsernameChangeButton', true);
        if (!storage.get('showUsername')) {

          return true;
        }
      } else {
        storage.remove('showUsernameChangeButton');
        storage.remove('showUsername');
        storage.remove('userDefinedUsername');
        
        if(!storage.get('isInsurancePromo') && rocketmanTransport.useInsurance === true && !rocketmanTransport.allLoginData?.player_data?.new_player) {
          rocketmanTransport.setShowInsurancePromo(true);
          storage.set('isInsurancePromo', true);
        }
      }
    }

    return false;
  } else {
    storage.remove('showUsernameChangeButton');
    storage.remove('showUsername');
    storage.remove('userDefinedUsername');
    if(!storage.get('isInsurancePromo') && rocketmanTransport.useInsurance === true && !rocketmanTransport.allLoginData?.player_data?.new_player) {
      rocketmanTransport.setShowInsurancePromo(true);
      storage.set('isInsurancePromo', true);
    }
  }
}
