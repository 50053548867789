import React, { useState } from "react";
import ArrowUpSvg from "./svg/arrow-up-svg";
import AvatarButton from "./AvatarButton";
import Username from "./Username";
import { Link } from "react-router-dom";
import avatars from "../model/avatars";
import translate from "../services/Translation/lang";
import ChangeUsernameButton from "./ChangeUsernameButton";
import storage from "../services/Storage/Storage";

const HeaderAvatar = (props) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className="position-relative">
      <button
        className={"header-avatar " + (isActive ? "active" : "inactive")}
        onClick={toggleClass}
      >
        <div className="wrapper">
          <div className="close-container">
            <div className="leftright"></div>
            <div className="rightleft"></div>
          </div>
        </div>
        <img src={avatars[`avatar${props.avatarId}`]} alt="Avatar" />
      </button>
      {isActive ? (
        <div className="header-avatar-dropdown">
          <div className="arrow-up">
            <ArrowUpSvg />
          </div>
          <div className="had-holder">
            <div className="had-avatar-holder">
              <img src={avatars[`avatar${props.avatarId}`]} alt="Avatar" />
            </div>
            <Username username={props.username} />
          </div>
          <Link
            onClick={toggleClass}
            className="menu-buttons"
            to="/user-avatars"
          >
            <AvatarButton title={translate("change_avatar")} />
          </Link>
          {storage.get("showUsernameChangeButton") ? (
            <>
              <div
                onClick={() => {
                  toggleClass();
                  window.setShowUserName(true);
                }}
              >
                <ChangeUsernameButton title={translate("change_username")} />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default HeaderAvatar;
