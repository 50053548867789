import React, { useEffect, useState } from "react";
import ButtonNavigator from "../components/main/ButtonNavigator";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainTitle from "../components/MainTitle";
import Avatar from "../components/Avatar";
import AvatarSelectedSvg from "../components/svg/avatar-selected-svg";
import ScrollAnimation from "react-animate-on-scroll";
import { Link, useHistory } from "react-router-dom";
import rocketmanTransport from "../services/RocketmanTransport";
import DesktopPageBackButton from "../components/DesktopPageBackButton";
import SimpleBar from "simplebar-react";
import avatars from "../model/avatars";

import "simplebar/dist/simplebar.min.css";
import translate from "../services/Translation/lang";
import { formatAvatarName } from "../services/AvatarNameHelper/avatarsHelper";

/**
 * Pages can consist of components
 */
export default function UserAvatars() {
  const OFFSET = 74;
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(rocketmanTransport.avatarId);
  const [isVip, setIsVip] = useState(false);

  const handleOnClick = (index) => {
    setActiveIndex(index); // remove the curly braces
  };

  useEffect(() => {
    setIsVip(rocketmanTransport.userIsVip);
  }, []);

  const save = () => {
    history.goBack();
    rocketmanTransport.requestSetAvatarId(activeIndex);
    window.setAvatarId(activeIndex);
    rocketmanTransport.avatarId = activeIndex;
    rocketmanTransport.chatConn.close();
  };

  return (
    <div className="page-holder with-provably with-bottom-navigator">
      <div className="container">
        <div className="desktop-page-holder desktop-page-holder-avatar">
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <div className="desktop-page-back">
              <MainTitle title={translate("change_avatar")} />
              <DesktopPageBackButton />
            </div>
            <p className="mb-3">{translate("pick_your_avatar_img")}:</p>
            <hr className="desktop-separator-line"></hr>
          </ScrollAnimation>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={500}
            duration={1}
            animateIn="fadeIn"
          >
            {/* <div className="avatars-holder"> */}
            <SimpleBar
              className="avatars-holder"
            >
              {Object.keys(avatars).map((item, index) => {
                if (!isVip && index > 49) return;
                return (
                  <button
                    key={index}
                    className={
                      activeIndex === index
                        ? "avatar-container avatar-active"
                        : "avatar-container"
                    }
                    style={index > 49 ? { order: -1 } : {}}
                    onClick={() => handleOnClick(index)}
                  >
                    <div className="avatar-selected">
                      <AvatarSelectedSvg />
                    </div>
                    <Avatar
                      avatar={avatars[item]}
                      avatarTitle={
                        index > 49
                          ? `VIP ${index - 50}`
                          : formatAvatarName(item)
                      }
                      vipAvatar={index > 49 ? true : false}
                    />
                  </button>
                );
              })}
            </SimpleBar>
            {/* </div> */}
          </ScrollAnimation>
          <div className="button-footer">
            <Link
              className="menu-buttons"
              to="/menu"
              onClick={(event) => event.preventDefault()}
            >
              <SecondaryButton
                title={translate("cancel")}
                onClick={() => history.goBack()}
              />
            </Link>
            <PrimaryButton title={translate("save")} onClick={() => save()} />
          </div>
        </div>
      </div>
      <div>
        <ButtonNavigator>
          <div className="container">
            <Row className="gutter-2 align-items-center">
              <Col>
                <Link
                  className="menu-buttons"
                  to="/menu"
                  onClick={(event) => event.preventDefault()}
                >
                  <SecondaryButton
                    title={translate("cancel")}
                    onClick={() => history.goBack()}
                  />
                </Link>
              </Col>
              <Col>
                <PrimaryButton
                  title={translate("save")}
                  onClick={() => save()}
                />
              </Col>
            </Row>
          </div>
        </ButtonNavigator>
      </div>
    </div>
  );
}
