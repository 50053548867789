import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MenuSvg from "../svg/menu-svg";
import MenuInactiveSvg from "../svg/menu-inactive-svg";
import ChatSvg from "../svg/chat-svg";
import ChatInactiveSvg from "../svg/chat-inactive-svg";
import PlayNowSvg from "../svg/play-now-svg";
import PlayNowInactiveSvg from "../svg/play-now-inactive-svg";
import BetsInactiveSvg from "../svg/bets-inactive-svg";
import BetsSvg from "../svg/bets-svg";
import translate from "../../services/Translation/lang";
import rocketmanTransport from "../../services/RocketmanTransport";
import ChatBadge from "../Chat/ChatBadge";

const BottomTabNavigator = (props) => {
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  window.setBottomLang = setLanguage;

  return (
    <div className="bottom-navigator">
      <NavLink
        className={"bottom-navigator-link " + (rocketmanTransport.r7css ? "r7css" : "")}
        activeClassName="active"
        to="/bets"
      > 
        {props.totalBets && props.totalBets > 0 ? (<div className={"pod-tabs-nav-badge " + (rocketmanTransport.r7css ? "r7css" : "")}>
          {props.totalBets }
        </div>) : null}
        <div className="bottom-navigator-link-opacity"></div>
        <BetsInactiveSvg className="icon-inactive mb-1" />
        <BetsSvg className="icon-active mb-1" />
        {translate("bets")}
      </NavLink>
      <NavLink
        className={"bottom-navigator-link " + (rocketmanTransport.r7css ? "r7css" : "")}
        activeClassName="active"
        exact={true}
        to="/"
      >
        <div className="bottom-navigator-link-opacity"></div>
        <PlayNowInactiveSvg className="icon-inactive mb-1" />
        <PlayNowSvg className="icon-active mb-1" />
        {translate("play_now")}
      </NavLink>
      {rocketmanTransport.isChatVisible ? <NavLink
        className={"bottom-navigator-link bottom-navigator-link-chat " + (rocketmanTransport.r7css ? "r7css" : "")}
        activeClassName="active"
        to="/chat"
      >
        <ChatBadge />
        <div className="bottom-navigator-link-opacity"></div>
        <ChatInactiveSvg className="icon-inactive mb-1" />
        <ChatSvg className="icon-active mb-1" />
        {translate("chat")}
      </NavLink> : null}
      <NavLink
        className={"bottom-navigator-link " + (rocketmanTransport.r7css ? "r7css" : "")}
        activeClassName="active"
        to="/menu"
      >
        <div className="bottom-navigator-link-opacity"></div>
        <MenuInactiveSvg className="icon-inactive mb-1" />
        <MenuSvg className="icon-active mb-1" />
        {translate("menu")}
      </NavLink>
    </div>
  );
};

export default BottomTabNavigator;
