import React from "react";

function StepRocketSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="url(#paint2_linear)"
        fillRule="evenodd"
        d="M23.625 24.864c.65.343 1.47-.024 1.501-.756.105-2.53-.326-6.085-2.527-7.732-.292-.218-.496-.545-.504-.909-.136-6.152-1.844-11.315-6.183-13.466 0 0 0-.001 0 0L15.91 2c-4.314 2.153-5.797 7.315-5.85 13.468-.003.363-.203.69-.492.909-2.182 1.646-2.57 5.202-2.435 7.732.04.731.864 1.098 1.51.755 2.85-1.512 6.018-2.057 7.48-2.145 1.463.088 4.636.634 7.501 2.145z"
        clipRule="evenodd"
      ></path>
      <g filter="url(#filter0_bi)">
        <path
          fill="#fff"
          fillOpacity="0.6"
          fillRule="evenodd"
          d="M24.461 30.083c.647.349 1.467-.017 1.504-.748.142-2.81-.31-6.875-2.822-8.706-.295-.215-.498-.543-.506-.906-.14-6.8-2.017-12.508-6.807-14.884h-.003c-4.76 2.376-6.388 8.085-6.434 14.884-.003.364-.202.692-.494.907-2.49 1.83-2.893 5.895-2.717 8.705.046.731.871 1.097 1.514.749 3.17-1.721 6.738-2.339 8.371-2.437 1.639.1 5.207.718 8.394 2.436z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="4"
        height="4"
        x="14.099"
        y="15.224"
        fill="#fff"
        rx="2"
        transform="rotate(-90 14.099 15.224)"
      ></rect>
      <defs>
        <filter
          id="filter0_bi"
          width="34.941"
          height="40.477"
          x="-1.403"
          y="-2.711"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="3.775"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="5.322"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow"></feBlend>
        </filter>
        <linearGradient
          id="paint2_linear"
          x1="-3.377"
          x2="15.952"
          y1="-20.973"
          y2="-27.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#633AFA"></stop>
          <stop offset="1" stopColor="#3BC4F2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default StepRocketSvg;
