import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

// parametar startTheRound will change pop up, the default pop up is info pop up

const HelpOffer = ({
  show,
  setShow
}) => {
  const history = useHistory();

  return (
    <Modal
      className="pop-up over-popup help"
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      backdropClassName="over-popup-backdrop help"
      enforceFocus={false}
    >
      <Modal.Body>
        <div className="astronaut-pop-up-body help-offer-body">
          <div className="pop-up-promo-text help">
            <div className="pop-up-promo-text-holder">
              <h1 className="pupt-vissible">{translate('first_time_playing')}</h1>
              <h1 className="pupt-hidden">{translate('first_time_playing')}</h1>
            </div>
          </div>
          <div className="pop-up-message-box">
          <p className="pop-up-message-description">
            {translate('we_have_noticed')}
            </p>
          
          </div>
          <div className="pop-up-close">
              <PrimaryButton
                title={translate('yes')}
                onClick={() => {setShow(false); history.replace("/how-to-play")}}
              />
              <SecondaryButton
                title={translate('no_thanks')}
                onClick={() => setShow(false)}
              />
            </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HelpOffer;
