import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import rocketmanTransport from "../services/RocketmanTransport";
import storage from "../services/Storage/Storage";
import moment from "moment";
import 'moment/locale/sr';
import 'moment/locale/ar';
import 'moment/locale/bg';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/mk';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sq';
import 'moment/locale/sv';
import 'moment/locale/tr';
import 'moment/locale/uk';
import 'moment/locale/zh-cn';
import "moment/locale/en-gb";
import translate from "../services/Translation/lang";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import Currency from "./Currency/Currency";
import MeteorShowerMessage from "./MeteorShower/MeteorShowerMessage";
import { showerMessageHelper } from "../services/Helpers/StringHelper";

// props
// entery - text of the message
// number of likes
// sharedBet
// if sharedBet is true message will be in form of share bet
// cashedOut
// round - number of round id
// winAmount
// betAmount
// toUser - username of winning user

const highLight = (mp) => {
  if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
  if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
  if (Number(mp) > 99.99) return "win-legendary";

  return "win-small";
}

const CurrentUserMessage = (props) => {
  const [count, setCount] = useState(props.numberOfLikes || 0);
  const [liked, setLiked] = useState(false);
  const [image, setImage] = useState(props?.gif);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);  

  function handleClick() {
    if (props.shower) {
      rocketmanTransport.requestChatLike(props.id, true, props.entry);
    } else {
      rocketmanTransport.requestChatLike(props.id);
    }
    
    if (!liked) {
      setCount(count + 1);
      storage.set(`myMsg-${props.username}-${props.id}`,1);
    }
    if (liked) {
      setCount(count - 1);
      storage.remove(`myMsg-${props.username}-${props.id}`);
    }
    setLiked(!liked);
  }

  window.setCurrUserMsgLang = setLanguage;

  useEffect(() => {
    if (language === 'CN') {
      setLanguage('zh-cn');
    }

    if (language === 'rs') {
      setLanguage('sr');
    }

    setCount(props.numberOfLikes || 0);
    if (storage.get(`myMsg-${props.username}-${props.id}`)) {
      setLiked(true);
    }
  },[props.numberOfLikes,props.id,props.username,language]);

  return (
    <div className="current-user-message">
      <p className="message-time">
        {moment(props.time).locale(moment.locale(language)).fromNow()}
      </p>
      <div className="message-avatar-holder">
        {props.sharedBet.toUser ? (
          <p className="message-to-user">@{props.sharedBet.toUser}</p>
        ) : null}
      </div>
      <div className="my-message-holder">
        <div className="my-message-like-holder">
          <div
            className={
              count === 0
                ? "d-none"
                : count === 1 && liked
                ? "d-none"
                : count === 1 && !liked
                ? "animated fadeInRight"
                : count >= 1 && liked
                ? "animated fadeOutRight"
                : count >= 2 && !liked
                ? "animated fadeInRight"
                : "d-none"
            }
          >
            <div className="my-message-number-of-likes-holder">
              <div className="my-message-number-of-likes">
                <img src="/svg/like-yellow.png" alt="like_yellow" width="15" height="17" />
              </div>
              <span className="">{count}</span>
            </div>
          </div>
          <div className="my-message-number-of-likes-clicked-holder">
            <button
              onClick={handleClick}
              className={"my-message-like " + (liked ? "liked" : "")}
            >
              {liked ? <img src="/svg/like-yellow.png" alt="like_yellow" width="15" height="17" /> : <img src="/svg/like.png" alt="like" />}
            </button>
            {liked ? <div>{count}</div> : null}
          </div>
        </div>
        {image ? (
          <div className="my-message-gif">
            <img src={image} alt="gif"  onError={()=> setImage("")} />
          </div>
        ) : props.sharedBet ? (
          <div className="my-message-shared">
            <div className="my-message-shared-top">
              <p>{props.sharedBet.entry}</p>
            </div>
            <div className="my-message-shared-bottom">
              <div className="mmsb-left">
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('cashed_out')}</p>
                  <p className={["mmsb-info-content", highLight(props.sharedBet.cashedOut)].join(" ")}>{props.sharedBet.cashedOut}x</p>
                </div>
                <div className="mmsb-info-divider"></div>
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('round')}:</p>
                  <p className="mmsb-info-content">{props.sharedBet.round}</p>
                </div>
              </div>
              <div className="mmsb-right">
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('win_amount')}</p>
                  {props.sharedBet.betCurrency == rocketmanTransport.myCurrency ? (
                    <p className="mmsb-info-content">{currencyFormatter(Number(props.sharedBet.winAmount).toFixed(rocketmanTransport.noOfDecimals))} <Currency>{rocketmanTransport.myCurrency}</Currency></p>
                  ): (
                    <p className="mmsb-info-content">{currencyFormatter(Number(Number(props.sharedBet.winEur) * Number(rocketmanTransport.myExchangeRate)).toFixed(rocketmanTransport.noOfDecimals))} <Currency>{rocketmanTransport.myCurrency}</Currency></p>
                  )}
                </div>
                <div className="mmsb-info-divider"></div>
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('bet_bets_tab')}:</p>
                  {props.sharedBet.betCurrency == rocketmanTransport.myCurrency ? (
                    <p className="mmsb-info-content">
                      {currencyFormatter(Number(props.sharedBet.betAmount).toFixed(rocketmanTransport.noOfDecimals))} <Currency>{rocketmanTransport.myCurrency}</Currency>
                    </p>
                    ) : 
                    (<p className="mmsb-info-content">
                      {currencyFormatter(Number(Number(props.sharedBet.betEur) * Number(rocketmanTransport.myExchangeRate)).toFixed(rocketmanTransport.noOfDecimals))} <Currency>{rocketmanTransport.myCurrency}</Currency>
                    </p>)}
                </div>
              </div>
            </div>
            <button className="provably-fair-shared">
              <img src="/svg/provably-fair-icon.png" alt="provably-fair-icon" />
            </button>
          </div>
        ) : (
          <div className={props.shower ? "my-message my-message-shower" : "my-message"}>
            {props.shower ? <div className="my-meteor-shower-message w-100"><MeteorShowerMessage claims={props.claims} currentUser={true} message={showerMessageHelper(props.entry)} /></div> :
             (<p>{props.entry}</p>)} 
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentUserMessage;
