import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import soundFxPlayer from "../services/Audio/SoundFxPlayer";
import translate from "../services/Translation/lang";

const HeaderDialogWarningMeteor = ({ msg, className }) => {
  const OFFSET = 80;
  const [thisMsg, setThisMsg] = useState("");

  useEffect(() => {

    let message = ""
    switch (msg) {
      case 0:
        // message = translate('this_game_is');
        message = "";
        break;
      case 1:
        message = translate('claming_failed');
        break;
      case 2:
        message = translate('claming_failed');
        break;
      case 3:
        message = translate('player_on_blacklist');
        break;
      case 4:
        message = translate('you_can_claim_ticket');
        break;
      case 5:
        message = translate('claming_failed');
        break;
      case 6:
        message = translate('claming_failed');
        break;
      case 7:
        message = translate('claming_failed');
        break;
      case 8:
        message = translate('you_already_have_free_tickets');
        break;
      default:
        message = "";
    }
  
    setThisMsg(message)
  }, [msg], translate)
  
  return (
    <ScrollAnimation
      animateOnce
      offset={OFFSET}
      delay={200}
      duration={1}
      animateIn="fadeInDown"
      animateOut="fadeInUp"
    >
      <div className={"cashout-header-dialog warning " + className}>
        <div>{thisMsg}</div>
        <button
          className="cashout-header-dialog-button close-container bet-info-close"
          onClick={() => {
            soundFxPlayer.play("you_have_cashed_out_close");
          }}
        >
          <div className="leftright"></div>
          <div className="rightleft"></div>
        </button>
      </div>
    </ScrollAnimation>
  );
};

export default HeaderDialogWarningMeteor;
