import rocketmanTransport from "../RocketmanTransport";

const customCurrencyFormatter = (amount, format) => {
  var formatedAmount = "";
  if (format === "###,###.00") {
    formatedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
  if (format === "###,###K") {
    if (amount >= 1000) {
      formatedAmount =
        new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(amount / 1000) + "K";
    } else {
      formatedAmount = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
    }
  }
  if (format === "###,###") {
    formatedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }
  return formatedAmount;
};

export const currencyFormatter = (
  number,
  format = rocketmanTransport.amountFormat
) => {
  if (format) {
    return customCurrencyFormatter(number, format);
  } else {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: rocketmanTransport.noOfDecimals,
      maximumFractionDigits: rocketmanTransport.noOfDecimals,
    }).format(number);
  }
};
