import React from "react";

function TableShareSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="#FFBF1A"
        fillRule="evenodd"
        d="M7.028 2.95c.53-.061.951-.496.951-1.03v-.918c0-.86 1.012-1.318 1.658-.753l5.684 4.974a1 1 0 010 1.505L9.637 11.7c-.646.566-1.658.106-1.658-.753V9.893c0-.51-.386-.947-.897-.954-2.183-.032-4.025.721-5.57 2.266a.78.78 0 01-1.33-.551c0-4.573 2.357-7.19 6.846-7.704z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TableShareSvg;
