import * as React from "react";

function BetsInactiveSvg(props) {
  return (
    <svg
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16v-1.07a6.999 6.999 0 004.781-2.982C18.495 11.574 20 9.223 20 6V3a2 2 0 00-2-2h-1.268A2 2 0 0015 0H5a2 2 0 00-1.732 1H2a2 2 0 00-2 2v3c0 3.223 1.505 5.574 4.219 5.948A6.999 6.999 0 009 14.929V16H8a2 2 0 00-2 2h8a2 2 0 00-2-2h-1zM2 3h1v5c0 .458.044.905.128 1.338C2.39 8.675 2 7.531 2 6V3zm15 0v5c0 .458-.044.905-.128 1.338C17.61 8.675 18 7.531 18 6V3h-1zM5 8V2h10v6A5 5 0 015 8z"
        fill="#fff"
      />
    </svg>
  );
}

export default BetsInactiveSvg;
