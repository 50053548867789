import React, { useRef, useState, useMemo, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useLocation } from "react-router-dom";
import AddAnotherBet from "../AddAnotherBet";
import HeaderDialog from "../HeaderDialog";
import WaitingForNextRound from "../BetComponent/WaitingForNextRound";
import BottomTabNavigator from "../main/BottomTabNavigator";
import JackpotsHeader from "../JackpotsHeader";
import PlayNowFooter from "../PlayNowFooter";
import Rocketman from "../rocketman/Rocketman";
import OhSnap from "../pop-ups/OhSnap";
import HeaderBonusGames from "../HeaderBonusGames";
import HelpOffer from "../pop-ups/HelpOffer";
import "simplebar/dist/simplebar.min.css";
import Buttons from "../Buttons/Buttons";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import LowEndRocketman from "../rocketman/LowEndRocketman";
import rocketmanTransport, {
  urlParams,
} from "../../services/RocketmanTransport";
import HeaderDialogWarning from "../HeaderDialogWarning";
import LoadingScreen from "../main/LoadingScreen";
import FullscreenMode from "../main/FullscreenMode";
import Expire from "../main/Expire";
import translate from "../../services/Translation/lang";
import { useHistory } from "react-router-dom";
import LostConnection from "../main/LostConnection";
import MaintenanceMode from "../main/MaintenanceMode";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import RoundCounter from "../RoundCounter";
import UserChangeUserName from "../main/UserChangeUserName";
import { setupUserNamePrompt } from "../../services/UsernameConfig";
import UserDefinedUsername from "../main/UserDefinedUsername";
import TournamentCounter from "../Tournament/TournamentCounter";
import TournamentLeaderboard from "../Tournament/TournamentLeaderboard";
//import HighScores from "../pop-ups/HighScores";
import { filterIsolatedBets } from "../../services/IsolatedBets/isolatedBets";
import RetailSlider from "../Retail/RetailSlider";
import AlienSaucerFreeBets from "../pop-ups/AlienSaucerFreeBets";
import AstronautPromoCredit from "../pop-ups/AstronautPromoCredit";

export default function PlayNowSubComponent(props) {
  const OFFSET = 74;
  const [isActive, setActive] = useState(false);
  const [firstButtonPlayed, setFirstButtonPlayed] = useState(false);
  const [showRoundCounter, setShowRoundCounter] = useState(false);
  const [gameStarted, setGameStarted] = useState(
    rocketmanTransport.gameStarted
  );
  const [showNoFunds, setShowNoFunds] = useState(false);
  const [changeUsernameShow, setChangeUsernameShow] = useState(null);
  // SET TRUE FOR PREVIEW
  const [nextTournamentActive, setNextTournamentActive] = useState(false);
  const [tournamentInProgress, setTournamentInProgress] = useState(false);
  // --------------------
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [warning, setWarning] = useState("");
  const [lostConnection, setLostConnection] = useState(false);
  const [animation, setAnimation] = useState(rocketmanTransport.animation);
  const history = useHistory();
  const [showStartButton, setShowStartButton] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [fullScreen, setFullScreen] = useState(rocketmanTransport.launcherFullScreen);
  const [startBtnMobileWaitForResponse,setStartBtnMobileWaitForResponse] = useState(false);
  const [minCashoutMultiplier, setMinCashoutMultiplier] = useState(0);
  
  const { width } = useWindowDimensions();
  const [usernamePrompt, setUsernamePrompt] = useState(false);
  const [showFreeBetsNotif, setShowFreeBetsNotif] = useState(false);
  const [freeBetsNotifParams, setFreeBetsNotifParams] = useState({
    betCount: "",
    betMoney: "",
    currency: "",
  });
  const demoCompanyId = rocketmanTransport.launcherCompanyId;
  const [freeBetsMsg, setFreeBetsMsg] = useState("");
  const [showAstronautPromo, setShowAstronautPromo] = useState(false);
  const [promoCreditVal, setPromoCreditVal] = useState(0);
  // const [showScreen, setShowScreen] = useState("READY_TO_FLY");

  // window.setMainShowScreen = (screen) => {
  //   if (showScreen !== screen) {
  //     setShowScreen(screen);
  //   }
  // };

  window.setFirstButtonPlayed = setFirstButtonPlayed;
  window.setShowRoundCounter = setShowRoundCounter;
  window.setGameStarted = setGameStarted;
  window.setShowNoFunds = setShowNoFunds;
  window.setAnimation = setAnimation;
  window.setWarning = setWarning;
  window.setLostConnection = setLostConnection;
  window.setShowStartButtonMobile = (data) => {
    setShowStartButton(data);
  };
  window.setMinCashoutMultiplierPlayNow = setMinCashoutMultiplier;

  window.setStartBtnMobileWaitForResponse = setStartBtnMobileWaitForResponse;
  window.setShowHelp = setShowHelp;
  window.setFullScreen = setFullScreen;
  window.setShowFreeBetsNotif = setShowFreeBetsNotif;
  window.setFreeBetsNotifParams = setFreeBetsNotifParams;
  window.setFreeBetsMsg = setFreeBetsMsg;
  window.setPromoCreditNotif = setShowAstronautPromo;
  window.setPromoCreditVal = setPromoCreditVal;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showStartRoundParams = () => {
      rocketmanTransport.freeTicketCount = freeBetsNotifParams.betCount;
      rocketmanTransport.freeTicketMoney = freeBetsNotifParams.betMoney;
      window.setFreeBetsCount(freeBetsNotifParams.betCount);
      window.setFreeBetsMoney(freeBetsNotifParams.betMoney);
      setFreeBetsNotifParams({
        betCount: "",
        betMoney: "",
        currency: "",
      });
    };
  /**
   * On b keyboard button clicked event ( main logic in button 0 )
   */
  window.betFirstButtonByKey = () => {
    if (!firstButtonPlayed) {
      //toggleClass();
      //call buy on first button
      window.betFirstButton();
    }
  };

  window.showTournametInProgress = setTournamentInProgress;
  window.showTorunametInfo = setNextTournamentActive;
  window.setMaintenanceMode = setMaintenanceMode;

  /**
   * On c keyboard button clicked event  ( main logic in button 0 )
   */
  window.cancelOrCashoutFirstButton = () => {
    if (firstButtonPlayed) {
      //call buy on first button
      window.firstButtonCancelOrCashout();
    } else {
      window.canceZeroIndexButton();
    }
  };

  const toggleClass = () => {
    if (isActive) {
      soundFxPlayer.play("scroll_2");
    } else {
      soundFxPlayer.play("scroll_1");
    }
    setActive(!isActive);
  };

  useEffect(() => {
    if (props.secondBetButtonPlayed) {
      setActive(true);
    }
  }, [props.secondBetButtonPlayed]);

  const initUsernamePrompt = async () => {
    const usernamePrompt = await setupUserNamePrompt();
    if (usernamePrompt) {
      setUsernamePrompt(usernamePrompt);
    }
  };

  useEffect(() => {
    if (urlParams.get("showHelp")) {
      history.replace("/how-to-play");
    }
    initUsernamePrompt();
  }, []);

  useEffect(() => {
    if (
      width >= 1024 &&
      (location.pathname === "/bets" ||
        location.pathname === "/menu" ||
        location.pathname === "/chat")
    ) {
      history.replace("/");
    }
  }, [width, history]);

  const hideRocketman = useRef(false);
  const location = useLocation();

  const appElem = document.querySelector(".App");
  const rootElem = document.getElementById("root");

  if (location.pathname !== "/") {
    hideRocketman.current = true;
    if (appElem && rootElem) {
      document.querySelector("html").classList.remove("full-height");
      document.querySelector("body").classList.remove("full-height");
      document.getElementById("root").classList.remove("full-height");
      document.querySelector(".App").classList.remove("full-height");
    }
  } else {
    hideRocketman.current = false;
    if (appElem && rootElem) {
      document.querySelector("html").classList.add("full-height");
      document.querySelector("body").classList.add("full-height");
      document.getElementById("root").classList.add("full-height");
      document.querySelector(".App").classList.add("full-height");
    }
  }

  if (location.pathname === "/menu") {
    if (appElem && rootElem) {
      document.querySelector("html").classList.add("full-height");
      document.querySelector("body").classList.add("full-height");
      document.getElementById("root").classList.add("full-height");
      document.querySelector(".App").classList.add("full-height");
    }
  }

  const totalBets =  Object.values(props.allBets).filter((bet) => bet.round_id === props.currRound && filterIsolatedBets(bet)).length;


  if (rocketmanTransport.retail) {
    document.querySelector("body").classList.add("retail");
  }

  const headerBonusGameClass = () => {
    if (rocketmanTransport.launcherHideHeader == 1 && rocketmanTransport.tournamentStatus == 3) {
      return "header-bonus-games-tournament-no-header"
    }
    if (rocketmanTransport.launcherHideHeader == 1) {
      return "no-header"
    }
    if (rocketmanTransport.tournamentStatus == 3) {
      return "header-bonus-games-tournament"
    }
    else return ""
  }

  useEffect(() => {
      setChangeUsernameShow(rocketmanTransport.usernamePopup)
  }, [rocketmanTransport.usernamePopup])

  return (
    <><div
      className="rocketman-container"
      style={{
        paddingTop: 0,
        display: (hideRocketman.current && "none") || "flex",
      }}
    >
      {demoCompanyId == 1 ? (
        <div className="demo-badge">
          <div className="sticker">DEMO</div>
        </div>
      ) : null}
      {!hideRocketman.current && (
        <ScrollAnimation
          animateOnce
          offset={OFFSET}
          delay={100}
          duration={1}
          animateIn="fadeInDown"
          className={width < 1024 && rocketmanTransport.launcherHideHeader == 1 ? "jackpots-header-holder top-0" : "jackpots-header-holder"}
          style={rocketmanTransport.retail ? {opacity: 1} : {}}
        >
          <JackpotsHeader
            jackpot={9458.83}
            rocketpot={1233.51}
            boosterpot={568.22}
          />
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={100}
            duration={1}
            animateIn="fadeInDown"
            className="cashout-header-dialog-holder"
          >
            {warning && (
              <Expire delay="5000" stateChanger={setWarning}>
                <HeaderDialogWarning msg={warning} />
              </Expire>
            )}
            {showNoFunds && (
              <Expire delay="5000" stateChanger={setShowNoFunds}>
                <HeaderDialogWarning
                  msg={translate("insufficient_funds") + " !"}
                />
              </Expire>
            )}
            {props.cashedOuts.map((cashedOut, index) => {
              return (
                <HeaderDialog
                  amount={cashedOut.win}
                  index={index}
                  key={index}
                  clearCashout={props.clearCashout}
                  cashedOuts={props.cashedOuts}
                />
              );
            })}
          </ScrollAnimation>
        </ScrollAnimation>
      )}
      {props.freeBetsCount ||
          props.promoBalance ||
          props.uncreditedWins > 0 ? (
            <HeaderBonusGames
              className={headerBonusGameClass()}
              betCount={props.freeBetsCount}
              betMoney={props.freeBetsMoney}
              showStartButton={showStartButton}
              setShowStartButton={setShowStartButton}
              promoCredit={props.promoBalance}
              uncreditedWins={props.uncreditedWins}
              startBtnWaitForResponse={startBtnMobileWaitForResponse}
              minCashoutMultiplier={minCashoutMultiplier}
            />
      ) : null}
      <div className="page-holder with-bottom-navigator play-now-page-holder">
        {tournamentInProgress ? <TournamentLeaderboard /> : null}
        {nextTournamentActive ? <TournamentCounter /> : null}
        {useMemo(
          () => (
            <LoadingScreen
              gameStarted={gameStarted}
              warning={warning ? warning : ""}
            />
          ),
          [gameStarted, warning]
        )}
        {useMemo(
          () => (
            <LostConnection lostConnection={lostConnection} />
          ),
          [lostConnection]
        )}
        {useMemo(
          () => (
            <MaintenanceMode maintenanceMode={maintenanceMode} />
          ),
          [maintenanceMode]
        )}
        {urlParams.get("version") !== "desktop" &&
          fullScreen && <FullscreenMode />}

        {useMemo(
          () => (
            <UserChangeUserName />
          ),
          []
        )}
        {
          useMemo(
            () => showHelp ? (<HelpOffer setShow={setShowHelp} show={showHelp} />) : usernamePrompt && changeUsernameShow && !rocketmanTransport.retail && (<UserDefinedUsername />),
            [showHelp,usernamePrompt, rocketmanTransport.retail]
          )
        }
        {useMemo(
          () => (animation ? <Rocketman /> : <LowEndRocketman />),
          [animation]
        )}
        {useMemo(
            () => (
              <PlayNowFooter multipliersHistory={props.multipliersHistory} />
            ),
            [props.multipliersHistory.length]
          )}
        {/* {useMemo(() => {
          if (animation) {
            if (!rocketmanTransport.retail) {
              return <Rocketman />;
            } else if (rocketmanTransport.retail && (showScreen === 'READY_TO_FLY' || showScreen === 'FLY')) {
              return <Rocketman />;
            } else {
              return null;
            }
          } else {
            return <LowEndRocketman />;
          }
        }, [animation, showScreen, rocketmanTransport.retail])} */}
        <div className="rocketman-scroll-holder">
          <div className={"container " + (rocketmanTransport.retail ? "retail" : " ")}>
            {/* {useMemo(
              () => (
                <HighScores show={props.endedAndCashouted} />
              ),
              [props.endedAndCashouted]
            )} */}
            {!rocketmanTransport.retail ? (
                <div className={"my-2 position-relative "  + (rocketmanTransport.branding === "betconstruct" ? "betconstruct-bet-buttons" : "")}>
                {props.gameState === "betActive" && <WaitingForNextRound />}
                {showRoundCounter && !rocketmanTransport.retail ? <RoundCounter /> : null}

                <Buttons
                  gameState={props.gameState}
                  isActive={isActive}
                  currRound={props.currRound}
                  secondBetButtonPlayed={props.secondBetButtonPlayed}
                />
                {rocketmanTransport.singleBet || rocketmanTransport.buttons[0]?.gameState === "betInProgress" ||
                rocketmanTransport.buttons[1]?.gameState === "betInProgress" ? (
                  <></>
                ) : (props.gameState === "betActive" &&
                    props.secondBetButtonPlayed) ||
                  firstButtonPlayed ? (
                  <AddAnotherBet isActive={isActive} disabled={true} />
                ) : (
                  <AddAnotherBet
                    onClick={() => toggleClass()}
                    isActive={isActive}
                    disabled={props.gameState === "betInProgress" ? true : false}
                  />
                )}
              </div>
            ) : null}
            
          </div>
          
        </div>
      </div>
      
      {useMemo(
        () => (
          <OhSnap show={props.rockedSnapped && !rocketmanTransport.retail} insuranceSnapped={props.insuranceSnapped} />
        ),
        [props.rockedSnapped,props.insuranceSnapped]
      )}
      {
        useMemo(
          () => (
        rocketmanTransport.retail ?
        <RetailSlider /> 
        : null
        ),
        [rocketmanTransport.retail]
      ) 
      }
      {useMemo(
          () => (
            <AlienSaucerFreeBets
              setShow={setShowFreeBetsNotif}
              showStartRoundParams={showStartRoundParams}
              show={showFreeBetsNotif}
              setShowFreeBetsNotif={setShowFreeBetsNotif}
              bonusCredit={freeBetsNotifParams.betMoney}
              bonusCash={freeBetsNotifParams?.betCount}
              currency={freeBetsNotifParams?.currency}
              freeBetsMsg={freeBetsMsg}
              promoText={translate("alien_saucer_free_bets")}
              startTheRound={false}
            />
          ),
          [
            freeBetsMsg,
            freeBetsNotifParams?.betCount,
            freeBetsNotifParams.betMoney,
            freeBetsNotifParams?.currency,
            showStartRoundParams,
            showFreeBetsNotif
          ]
        )}
        {useMemo(
          () => (
            <AstronautPromoCredit
              setShow={setShowAstronautPromo}
              show={showAstronautPromo}
              bonusCredit={promoCreditVal}
              promoText={translate("austronaut_promo_credit")}
            />
          ),
          [promoCreditVal, showAstronautPromo, setShowAstronautPromo]
        )}
    </div>
    {useMemo(
        () => (
          <BottomTabNavigator totalBets={totalBets} />
        ),
        [totalBets]
      )}
    </>
  );
}