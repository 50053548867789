import * as React from "react";

function ChatSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.087 16L4 19.804V16H2a2 2 0 01-2-2V2a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2h-7.913zM5 11V9h7v2H5zm0-4V5h9v2H5z"
        fill="#fff"
      />
    </svg>
  );
}

export default ChatSvg;
