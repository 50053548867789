import React from "react";
import menuIcons from "../model/menu-icons";
import Switch from "react-switch";
import translate from "../services/Translation/lang";
import rocketmanTransport from "../services/RocketmanTransport";

const MenuItem = (props) => {

  const handleChange = (nextChecked) => {
    props.toggle(nextChecked);
  };
  return (
    <div className="menu-item">
      <div className="menu-image-holder">
        <img src={menuIcons[props.image]} alt="" />
      </div>
      <div className="menu-item-switch-holder">
        <h5 className="menu-item-title">{props.title}</h5>
        <Switch
          onChange={handleChange}
          checked={props.checked}
          className={props.checked ? "move-left react-switch" : "react-switch"}
          onColor="#202558"
          offColor="#202558"
          borderRadius={26}
          width={100}
          height={30}
          handleDiameter={28}
          activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
          uncheckedIcon={<div className={"switcher-on-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('on')}</div>}
          checkedIcon={<div className={"switcher-off-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('off')}</div>}
          uncheckedHandleIcon={<div className={"switcher-off " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('off')}</div>}
          checkedHandleIcon={<div className={"switcher-on " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('on')}</div>}
        />
      </div>
    </div>
  );
};

export default MenuItem;
