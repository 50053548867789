import React from "react";
import Modal from "react-bootstrap/Modal";
import BoosterpotImage from "../../img/boosterpot.png";
import RocketpotImage from "../../img/rocketpot.png";
import JacketpotImage from "../../img/jacketpot.png";
import JacketpotBottom from "../../img/jacketpot-bottom.png";
import JacketpotTop from "../../img/jacketpot-top.png";
import JackpotCoins from "../../img/jackpot-coins.png";
import PrimaryButton from "../PrimaryButton";
import rocketmanTransport from "../../services/RocketmanTransport";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import FloatingCoinFirstSvg from "../svg/floating-coins/floating-coin-first-svg";
import FloatingCoinSecondSvg from "../svg/floating-coins/floating-coin-second-svg";
import FloatingCoinThirdSvg from "../svg/floating-coins/floating-coin-third-svg";
import FloatingCoinFourthSvg from "../svg/floating-coins/floating-coin-fourth-svg";
import FloatingCircleGreenSvg from "../svg/floating-circles/floating-circle-green-svg";
import FloatingCircleBlueSvg from "../svg/floating-circles/floating-circle-blue-svg";
import FloatingCirclePurpleSvg from "../svg/floating-circles/floating-circle-purple-svg";
import FloatingCircleOrangeSvg from "../svg/floating-circles/floating-circle-orange-svg";
import FloatingCoin from "../FloatingCoin";
import translate from "../../services/Translation/lang";
import Currency from "../Currency/Currency";

// parametar startTheRound will change pop up, the default pop up is info pop up

const JackpotPopups = ({
  show,
  setShow,
  bonusCash,
  boosterpot,
  rocketpot,
  jacketpot,
}) => {
  const handleStartFreeBets = () => {
    setShow(0);
    rocketmanTransport.startPlayFreeBets();
  };
  return (
    <Modal
      className="pop-up over-popup jackpot-modal"
      show={show}
      onHide={() => setShow(0)}
      animation={false}
      backdropClassName="over-popup-backdrop over-jackpot-backdrop"
      enforceFocus={false}
    >
      {jacketpot && (
        <div className="floating-set">
          <div className="floating-coins-holder" >
            {new Array(80).fill(0,0,80).map((coin, index) => {
              return  <FloatingCoin key={index}  delay={(index + 1) * 100}/>
            })}
        </div>
      </div>
      )}
      {jacketpot ? (
        <div>
          <div className="floating-circle-holder">
            <FloatingCircleGreenSvg className="floating floating-circle-green" />
            <FloatingCircleBlueSvg className="floating-reverse floating-circle-blue" />
            <FloatingCirclePurpleSvg className="floating-slow floating-circle-purple" />
            <FloatingCircleOrangeSvg className="floating-slow floating-circle-orange" />
            <FloatingCirclePurpleSvg className="floating floating-circle-purple-second" />
            <FloatingCircleGreenSvg className="floating-reverse floating-circle-green-second" />
          </div>
        </div>
      ) : rocketpot ? (
        <div className="floating-circle-holder">
          <FloatingCircleGreenSvg className="floating floating-circle-green" />
          <FloatingCircleBlueSvg className="floating-reverse floating-circle-blue" />
          <FloatingCirclePurpleSvg className="floating-slow floating-circle-purple" />
          <FloatingCircleOrangeSvg className="floating-slow floating-circle-orange" />
          <FloatingCirclePurpleSvg className="floating floating-circle-purple-second" />
          <FloatingCircleGreenSvg className="floating-reverse floating-circle-green-second" />
        </div>
      ) : null}
      <Modal.Body>
        <div className="astronaut-pop-up-body">
          <div className="pop-up-message-box">
            {jacketpot ? (
              <div className="jacketpot-top">
                <img src={JacketpotTop} alt="jacketpot-top" />
              </div>
            ) : null}
            <div className="jackpot-popup-image">
              <img
                src={
                  boosterpot
                    ? BoosterpotImage
                    : rocketpot
                    ? RocketpotImage
                    : jacketpot
                    ? JacketpotImage
                    : null
                }
                alt="boosterpot"
              />
            </div>
            <p className="jackpot-popup-text">{translate('you_have_won')}</p>
            <div
              className={
                "jackpot-promo-text " +
                (boosterpot
                  ? "boosterpot-text"
                  : rocketpot
                  ? "rocketpot-text"
                  : jacketpot
                  ? "jacketpot-text"
                  : null)
              }
            >
              <div className="pop-up-promo-text-holder">
                <h1 className="pupt-vissible">
                  {boosterpot
                    ? "Boosterpot"
                    : rocketpot
                    ? "Rocketpot"
                    : jacketpot
                    ? "Jackpot"
                    : ""}
                  !
                </h1>
                <h1 className="pupt-hidden">
                  {boosterpot
                    ? "Boosterpot"
                    : rocketpot
                    ? "Rocketpot"
                    : jacketpot
                    ? "Jackpot"
                    : ""}
                  !
                </h1>
              </div>
            </div>
            <div
              className={
                "pop-up-promo-win " +
                (boosterpot
                  ? "boosterpot"
                  : rocketpot
                  ? "rocketpot"
                  : jacketpot
                  ? "jacketpot"
                  : "")
              }
            >
              {boosterpot || rocketpot ? (
                <img
                  className="jackpot-coins"
                  src={JackpotCoins}
                  alt={boosterpot ? "Boosterpot" : ""}
                />
              ) : null}
              <div className="pop-up-promo-bonus-holder">
                <h1 className="pupb-vissible">
                  +{bonusCash}
                  <Currency>{rocketmanTransport.myCurrency}</Currency>
                </h1>
                <h1 className="pupb-hidden">
                  +{bonusCash}
                  <Currency>{rocketmanTransport.myCurrency}</Currency>
                </h1>
              </div>
            </div>
            {jacketpot ? (
              <div className="jacketpot-bottom">
                <img src={JacketpotBottom} alt="jacketpot-bottom" />
              </div>
            ) : null}
          </div>
          <div className="pop-up-close jackpot-popup-close">
            <PrimaryButton
              title={translate('collect_win')}
              onClick={() => {
                soundFxPlayer.play("selectBTN_1");
                setShow(0);
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JackpotPopups;
