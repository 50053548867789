import React, { useEffect, useState } from 'react'
import translate from '../../services/Translation/lang';

export default function DektopOnlinePlayers() {

  const [onlinePlayers, setOnlinePlayers] = useState(1);
  
  useEffect(() => {
    window.setOnlinePlayersDesktop = setOnlinePlayers;

    return () => {
      window.setOnlinePlayersDesktop = null;
    };
  }, []);

  return (
    <div className="desktop-chat-online">
      <p>{translate('online')}:</p>
      <span></span>
      <p>{onlinePlayers}</p>
    </div>
  )
}
