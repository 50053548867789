import React, { useState } from "react";
import { useEffect } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import TournamentCurrentTr from "../TournamentCurrentTr";

const TournamentCurrentTab = () => {
  // const PAGE_LENGTH = 50;
  // const [listLength, setListLength] = useState(50);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const [leaderboard, setLeaderboard] = useState([]);
  const [tournamentStatus, setTournamentStatus] = useState(0);
  const [mounted, setMounted] = useState(false);

  window.setAllBetsTabLang = (d) => {
    if (mounted) {
      setLanguage(d)
    }
  }; 
  window.setTableLeaderboard = (d) => {
    if (mounted) {
      setLeaderboard(d)
    }
  };

  window.setTournamentStatus = (d) => {
    if (mounted) {
      setTournamentStatus(d);
    }
  }

  useEffect(() => {
    setLeaderboard(rocketmanTransport.currentLeaderboard);
    setTournamentStatus(rocketmanTransport.tournamentStatus);
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return (
    <>
      {leaderboard.length > 0 ? (
        <table className="bets-table tournament-table tournament-table-current">
          <thead>
            <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
              <th>{translate("rank")}</th>
              <th>{translate("user")}</th>
              <th>{translate("points")}</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => {
              return (
                <TournamentCurrentTr
                  key={index}
                  rank={Number(player.Place)}
                  points={Number(player.Points)}
                  avatar={`avatar${player.AvatarID}`}
                  username={player.UserName}
                  flagCode={
                    player.CountryCode == "XK" && language === "sr"
                      ? "rs"
                      : player.CountryCode
                  }
                  isVip={player.AvatarID > 49 ? true : false}
                />
              );
            })}
          </tbody>
        </table>
      ) : tournamentStatus == 3 ? <h5>{translate('active_tournament_1')} {rocketmanTransport.tournamentName} {translate('active_tournament_2')} </h5> : (
        <h5>{translate('there_are_no_active_torunament')}</h5>
      )}
    </>
  );
};

export default TournamentCurrentTab;
