import React from "react";
import Flag from "react-world-flags";
import rocketmanTransport from "../../services/RocketmanTransport";
import { useState } from "react";
import { useEffect } from "react";


export default function ReactFlag({ className, code, height, width }) {
const [flagShow, setFlagShow] = useState(true);

useEffect(() => {
  setFlagShow(rocketmanTransport.isFlagEnabled)
}, [])

  return flagShow ? (
    <Flag className={className} code={code} height={height} width={width} />
  ) : null;
}
