import React, { useEffect, useState } from "react";
import BottomTabNavigator from "../components/main/BottomTabNavigator";
import ScrollAnimation from "react-animate-on-scroll";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AllBets from "../components/Bets/AllBets";
import BetWins from "../components/Bets/BetWins";
import MyBetsTab from "../components/bets-tabs/MyBetsTab";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useParams } from "react-router-dom";
import soundFxPlayer from "../services/Audio/SoundFxPlayer";
import translate from "../services/Translation/lang";
import TournamentTab from "../components/bets-tabs/TournamentTab";
import rocketmanTransport from "../services/RocketmanTransport";

export default function Bets() {
  const OFFSET = 74;
  const { selectedTab } = useParams();

  const [tabIndex, setTabIndex] = useState(Number(selectedTab) || 0);

  useEffect(() => {
    window.setTabIndex = setTabIndex;
  },[]);

  return (
    <div className="page-holder with-bottom-navigator bets-holder">
      <Tabs
        selectedTabClassName="selected-bet-tab"
        selectedIndex={tabIndex}
        onSelect={(index) => { 
          setTabIndex(index); 
          soundFxPlayer.play('menu_switch_2')
        }}
      >
        
          <TabList className={"bet-tabs-nav " + (rocketmanTransport.retail ? ' retail ' : ' ')}>
            <Tab
              className={"bet-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}
              disabledClassName="bet-tabs-nav-item-disabled"
            >
              <div className="bet-tabs-icon-holder">
                <div className="bets-inactive">
                <img src="/svg/all_bets_icon.png" alt="all_bet_active_icon" />
                </div>
                <div className="bets-active">
                <img src="/svg/all_bets_icon_active.png" alt="all_bet_icon" />
                </div>
              </div>
              <span>{translate('all_bets')}</span>
            </Tab>
            <Tab
              className={"bet-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}
              disabledClassName="bet-tabs-nav-item-disabled"
            >
              <div className="bet-tabs-icon-holder">
                <div className="bets-inactive">
                  <img src="/svg/my_bets_icon.png" alt="my_bets_icon" />
                </div>
                <div className="bets-active">
                  <img src="/svg/my_bets_icon_active.png" alt="my_bets_active_icon" />
                </div>
              </div>
              <span>{translate('my_bets')}</span>
            </Tab>
            <Tab
              className={"bet-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}
              disabledClassName="bet-tabs-nav-item-disabled"
            >
              <div className="bet-tabs-icon-holder">
                <div className="bets-inactive">
                  <img src="/svg/win_bets_icon.png" alt="win_bets_icon" />
                </div>
                <div className="bets-active">
                  <img src="/svg/win_bets_icon_active.png" alt="win_bets_active_icon" />
                </div>
              </div>
              <span>{translate('bet_wins')}</span>
            </Tab>
            <Tab
              className={"bet-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}
              disabledClassName="bet-tabs-nav-item-disabled"
            >
              <div className="bet-tabs-icon-holder">
                <div className="bets-inactive">
                  <img src="/svg/tournament-tab.png" alt="tournament-tab" />
                </div>
                <div className="bets-active">
                  <img src="/svg/tournament-tab-active.png" alt="tournament-tab_active_icon" />
                </div>
              </div>
              <span>{translate('tournament')}</span>
            </Tab>
          </TabList>
        
        <SimpleBar className={"desktop-betting-tabs " + (rocketmanTransport.retail ? "retail" : "")}>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            animateIn="fadeIn"
            duration={2}
          >
            <div>
              <TabPanel>
                <AllBets />
              </TabPanel>
              <TabPanel>
                  <MyBetsTab />
              </TabPanel>
              <TabPanel>
                <BetWins />
              </TabPanel>
              <TabPanel>
                <TournamentTab />
              </TabPanel>
            </div>
          </ScrollAnimation>
        </SimpleBar>
      </Tabs>
      {/* <BottomTabNavigator /> */}
    </div>
  );
}
