import { titleCase } from "../Helpers/StringHelper";
import translate from "../Translation/lang";



const BonusType = {
  1: titleCase(translate('jackpot')),
  2: titleCase(translate('rocketpot')),
  3:  titleCase(translate('boosterpot')),
  12: titleCase(translate('promo_credit')),
  13: titleCase(translate('free_bets'))
}

export default BonusType;
