
export default class AdvancedAutoPlayBalanceHandler {

  constructor() {
    this.balance = {
      0: 0,
      1: 0
    };
    this.initialBalance = {
      0: 0,
      1: 0,
    };
    this.balanceDiff = {
      0: 0,
      1: 0
    }
  }

  /**
   * setBalance
   * 
   * @param {number} buttonIndex 
   * @param {number} val 
   */
  setBalance(buttonIndex, val) {
    this.balance[buttonIndex] = val;
  }

  /**
   * 
   * @param {*} val 
   */
  setInitialBalance(buttonIndex, val) {
    this.initialBalance[buttonIndex] = val;
    try {
      window.setInitialBalance(buttonIndex,this.initialBalance[buttonIndex])
    } catch (error) {
      console.error("Cannot set initial balance for button " + buttonIndex);
    }
  }

  setBalanceDiff(buttonIndex, val) {
    this.balanceDiff[buttonIndex] = val;
    try {
      window.setAdvancedAutoPlayBalance(buttonIndex,this.balanceDiff[buttonIndex])
    } catch (error) {
      console.error("Cannot set balance diff for button " + buttonIndex);
    }
  }
}
