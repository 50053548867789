import React, { useEffect } from "react";
import ButtonNavigator from "../components/main/ButtonNavigator";
import SecondaryButton from "../components/SecondaryButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import MainTitle from "../components/MainTitle";
import BigTitle from "../components/BigTitle";
import ProvablyFairItem from "../components/ProvablyFairItem";
import ScrollAnimation from "react-animate-on-scroll";
import DividerLine from "../components/DividerLine";
import HowToCheck from "../img/how-to-check.png";
import GameHistory from "../img/game-history.png";
import DesktopPageBackButton from "../components/DesktopPageBackButton";
import translate from "../services/Translation/lang";

export default function ProvablyFairSystem() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const OFFSET = 74;
  return (
    <div className="page-holder with-bottom-navigator">
      <div className="container">
        <div className="desktop-page-holder">
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <div className="desktop-page-back">
              <MainTitle title={translate('what_is_provably_fair')} />
              <DesktopPageBackButton />
            </div>
            <p className="mb-3">
              {translate('provably_fair_1_paragraph')}
            </p>
            <DividerLine className="mb-3" />
          </ScrollAnimation>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={300}
            animateIn="fadeInUp"
          >
            <BigTitle title={translate('how_it_works')} />
            <MainTitle title={translate('quick_explanation')} className="mb-3" />
            <p className="text-white mb-3">
              {translate('provably_fair_2_paragraph')}
            </p>
          </ScrollAnimation>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={400}
            animateIn="fadeInUp"
          >
            <MainTitle title={translate('more_information')} className="mb-3" />
            <p className="text-white mb-3">
              {translate('provably_fair_3_paragraph')}
            </p>
            <p className="text-white mb-3">
             {translate('provably_fair_4_paragraph')}
            </p>
          </ScrollAnimation>
          <div className="provably-fair-explainer">
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={100}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem
                plus
                server
                title={translate('server_seed')}
                description="AB"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={200}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem
                plus
                player
                title={translate('player_1_seed')}
                description="CD"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={300}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem
                plus
                player
                title={translate('player_2_seed')}
                description="EF"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={400}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem
                arrow
                player
                title={translate('player_3_seed')}
                description="GH"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={500}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem
                arrow
                title={translate('combined_seed')}
                description="ABCDEFGH"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={600}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem
                arrow
                title={translate('hashed_in_sha_512')}
                description="8bafsg5sdgas5f4g5s85gxg5ga4h4h44fthfsdgasag4g44fg8888g5228bafsg5sdgas5f4g5s85gxg5ga4h4h44"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={700}
              animateIn="fadeInUp"
            >
              <ProvablyFairItem title={translate('round_results')} description="2.17" />
            </ScrollAnimation>
          </div>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={300}
            animateIn="fadeInUp"
          >
            <BigTitle title={translate('how_to_check')} />
          </ScrollAnimation>
          <div className="provably-fair-explainer-desktop">
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              duration={2}
              animateIn="fadeIn"
              className="how-to-check-image-holder"
            >
              <div className="provably-fair-image-holder">
                <img src={HowToCheck} alt="how-to-check" />
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={400}
              animateIn="fadeInUp"
              className="how-to-check-text-holder"
            >
              <MainTitle title={translate('1_from_game_history')} className="mb-3" />
              <p className="text-white mb-3">
                {translate('provably_fair_5_paragraph')}
              </p>
            </ScrollAnimation>
          </div>
          <div className="provably-fair-explainer-desktop">
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              duration={2}
              animateIn="fadeIn"
              className="how-to-check-image-holder"
            >
              <div className="provably-fair-image-holder">
                <img src={GameHistory} alt="how-to-check" />
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              offset={OFFSET}
              delay={500}
              animateIn="fadeInUp"
              className="how-to-check-text-holder"
            >
              <MainTitle title={translate('2_in_settings_window')} className="mb-3" />
              <p className="text-white mb-3">
                {translate('provably_fair_6_paragraph')}
              </p>
            </ScrollAnimation>
          </div>
          <ScrollAnimation
            animateOnce
            offset={OFFSET}
            delay={600}
            animateIn="fadeInUp"
          >
            <MainTitle title={translate('3_online_calculator')} className="mb-3" />
            <p className="text-white mb-3">
              {translate('provably_fair_7_paragraph')} <br className="d-block d-xl-none" />
              <a
                target="_blank"
                className="external-link"
                href="https://miniwebtool.com/sha512-hash-generator/"
              >
                https://miniwebtool.com/sha512-hash-generator/
              </a>
            </p>
          </ScrollAnimation>
        </div>
      </div>
      <ButtonNavigator>
        <div className="container">
          <Row className="gutter-2">
            <Col>
              <Link className="menu-buttons" to="/menu">
                <SecondaryButton title="Close" />
              </Link>
            </Col>
          </Row>
        </div>
      </ButtonNavigator>
    </div>
  );
}
