import AdvancedAutoPlayBalanceHandler from "./AdbancedAutoPlayBalanceHandler";
import AdvancedAutoPlayRoundsHandler from "./AdvancedAutoPlayRoundsHandler";


class AdvancedAutoPlayStateHandler {

  constructor(advancedAutoPlayBalanceHandler, advancedAutoPlayRoundsHandler) {
    this.advancedAutoPlayBalanceHandler = advancedAutoPlayBalanceHandler;
    this.advancedAutoPlayRoundsHandler = advancedAutoPlayRoundsHandler;
  }

  handleBalance(buttonIndex, advancedAutoPlayInProgress, rocketmanBalance) {
    if (advancedAutoPlayInProgress) {
      if (!this.advancedAutoPlayBalanceHandler.initialBalance[buttonIndex]) {
        this.advancedAutoPlayBalanceHandler.setInitialBalance(buttonIndex, rocketmanBalance);
      } 
      this.advancedAutoPlayBalanceHandler.setBalance(buttonIndex,rocketmanBalance);
      this.advancedAutoPlayBalanceHandler.setBalanceDiff(buttonIndex, this.advancedAutoPlayBalanceHandler.balance[buttonIndex] - this.advancedAutoPlayBalanceHandler.initialBalance[buttonIndex]);
      
    } else {
      this.advancedAutoPlayBalanceHandler.setInitialBalance(buttonIndex,0);
      this.advancedAutoPlayBalanceHandler.setBalance(buttonIndex,0);
      this.advancedAutoPlayBalanceHandler.setBalanceDiff(buttonIndex,0);
    }
  }

  handleRoundsPlayed(buttonIndex, advancedAutoPlayInProgress, currRound, ticket = undefined) {
    if (advancedAutoPlayInProgress) {
      this.advancedAutoPlayRoundsHandler.setRoundsPlayed(buttonIndex, currRound, ticket);
    } else {
      this.advancedAutoPlayRoundsHandler.setRoundsPlayed(buttonIndex,currRound, ticket, true)
    }
  }
}

const advancedAutoPlayStateHandler = new AdvancedAutoPlayStateHandler(new AdvancedAutoPlayBalanceHandler(), new AdvancedAutoPlayRoundsHandler());
export default advancedAutoPlayStateHandler;
