import React, { useMemo } from 'react';
import BetButton0 from '../Buttons/Button0';
import BetButton1 from '../Buttons/Button1';

export default function Buttons({gameState, isActive, currRound, secondBetButtonPlayed, className}) {
  return useMemo(() => (
    <div className={className}>
{/*       {isActive && ( */}
        <BetButton1 isActive={isActive} currRound={currRound} secondBetButtonPlayed={secondBetButtonPlayed} />
{/*       )} */}
      <BetButton0 isActive={isActive} currRound={currRound} secondBetButtonPlayed={secondBetButtonPlayed} />
    </div>
  ),[gameState, isActive, currRound,secondBetButtonPlayed]);
}
