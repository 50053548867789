import React, { useState } from "react";
// import rocketmanTransport from "../../services/RocketmanTransport";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sticky from "react-sticky-el";
import TournamentCurrentTab from "./TournamentCurrentTab";
import TournamentPrevTab from "./TournamentPrevTab";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";

const TournamentTab = () => {

  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Tabs
      selectedTabClassName="selected-pod-tab"
      selectedIndex={tabIndex}
      onSelect={(index) => {
        setTabIndex(index);
        soundFxPlayer.play("slider");
      }}
    >
      <Sticky
        topOffset={-74}
        className="sticky-header-holder"
        stickyClassName="sticky-header"
      >
        <header>
          <TabList className="pod-tabs-nav">
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('current')}</Tab>
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate('previous')}</Tab>
          </TabList>
        </header>
      </Sticky>

      <div className="pod-tabs-panel-holder">
        <div className="container">
          <TabPanel>
            <TournamentCurrentTab />
          </TabPanel>
        </div>
        <div className="container">
          <TabPanel>
            <TournamentPrevTab />
          </TabPanel>
        </div>
      </div>
    </Tabs>
  );
};

export default TournamentTab;
