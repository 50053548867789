import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import rocketmanTransport from "../../services/RocketmanTransport";

export default function ChatBadge(props) {
  const [messagesCounter, setMessagesCounter] = useState(0);

  const location = useLocation();

  useEffect(() => {
    setMessagesCounter(rocketmanTransport.messagesCounter);
  }, []);

  useEffect(() => {
    window.setMessagesCounter = setMessagesCounter;

    return () => {
      window.setMessagesCounter = null;
    };
  }, [rocketmanTransport.messagesCounter]);

  useEffect(() => {
    if (location.pathname === "/chat") {
      rocketmanTransport.messagesCounter = 0;
      setMessagesCounter(0);
    }
  }, [location.pathname, rocketmanTransport.messagesCounter]);

  return (
    messagesCounter > 0 && (
      <div className={"pod-tabs-nav-badge " + (rocketmanTransport.r7css ? "r7css" : "")}>
        {messagesCounter >= 100 ? "99+" : messagesCounter}
      </div>
    )
  );
}
