import * as React from "react";

function HeaderMenuBurgerSvg(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1a1 1 0 011-1h10a1 1 0 110 2H1a1 1 0 01-1-1zm0 5a1 1 0 011-1h10a1 1 0 110 2H1a1 1 0 01-1-1zm1 4a1 1 0 100 2h10a1 1 0 100-2H1z"
        fill="#fff"
      />
    </svg>
  );
}

export default HeaderMenuBurgerSvg;
