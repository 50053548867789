import Flag from "../components/Flag/Flag";
import avatars from "../model/avatars";
import { handleRank } from "../services/Helpers/HandleRank";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";

const TournamentLeaderboardTr = (props) => {
  return (
    <div className="tournament-leaderboard-tr">
      <div className="d-flex">
        <div className="tlt-position-holder">
          <span className="tournament-current-tr-rank">{props.rank ? props.rank : "-"}</span>
          {props.rank <= 60 ? (
            <img
              className={"tournament-rank-image " + handleRank(props.rank)}
              src={`/svg/tournament/ranks/${handleRank(props.rank)}.png`}
              alt="tournament-first"
            />
          ) : null}
        </div>
        <div className="all-bets-td-holder">
          <div className="all-bets-tr-avatar">
            <img src={avatars[props.avatar]} alt="" />
          </div>
          <span className="all-bets-tr-username">
            {props.username}
            {props.isVip ? (
              <img
                className="table-vip-avatar-marker"
                src="/svg/crown.svg"
                alt="crown"
              />
            ) : null}
          </span>
          <div className="all-bets-tr-flag">
            {props.flagCode === "XS" ? (
              <Flag className="xs-flag" code={"RU"} height="10" width="14" />
            ) : (
              <Flag code={props.flagCode} height="10" width="14" />
            )}
          </div>
        </div>
      </div>
      <div className="tournament-leaderboard-tr-points">
        <img
          src="/svg/tournament/points-icon.png"
          alt="points-icon"
        />
        {currencyFormatter(props.points)}
      </div>
    </div>
  );
};

export default TournamentLeaderboardTr;
