import React, { useEffect, useRef, useState } from "react";
import Bets from "../pages/Bets";
import Chat from "../pages/Chat";
import PlayNow from "../pages/PlayNow";
import BetVerify from "../pages/BetVerify";
import ChatIconSvg from "../components/svg/chat-icon-svg";
import { useLocation } from "react-router-dom";
import rocketmanTransport from "../services/RocketmanTransport";
import translate from "../services/Translation/lang";
import DesktopOnlinePlayers from "../components/Chat/DektopOnlinePlayers";
import BetInfo from "./BetInfo";
import useWindowDimensions from "../hooks/useWindowDimensions";
import RetailRoundId from "../components/Retail/RetailRoundId";
import RetailTotalBetsBadge from "../components/Retail/RetailTotalBetsBadge";

export default function Home() {
  const hideRocketman = useRef(false);

  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");

  const location = useLocation();
  const { width } = useWindowDimensions();

  const [isActive, setActive] = useState(true);
  const [betInfoActive, setBetInfoActive] = useState(false);
  const [betVerifyActive, setBetVerifyActive] = useState(false);
  const [ticketRid, setTicketRid] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [ticketTime, setTicketTime] = useState("");
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);

  window.setHomeLang = setLanguage;

  window.setBetInfoActive = (status, ticketId = '') => {
    setTicketId(ticketId);
    setBetInfoActive(status);
  };
  window.setBetVerifyActive = setBetVerifyActive;
  window.setTicketRid = setTicketRid; // sets ticket round id from bet rows for hash verify
  window.setTicketTime = setTicketTime; // sets ticket time from bet rows for hash verify


  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (isActive) {
      if (version === "desktop") {
        if (width >= 1024) {
          rocketmanTransport.updateChat = true;
        }
      } else {
        rocketmanTransport.updateChat = false;
        }
      }
  }, [isActive,width, version]);

  const appElem = document.querySelector(".App");
  const rootElem = document.getElementById("root");

  if (location.pathname !== "/") {
    hideRocketman.current = true;
    if (appElem && rootElem) {
      document.querySelector("html").classList.remove("full-height");
      document.querySelector("body").classList.remove("full-height");
      document.getElementById("root").classList.remove("full-height");
      document.querySelector(".App").classList.remove("full-height");
    }
  } else {
    hideRocketman.current = false;
    if (appElem && rootElem) {
      document.querySelector("html").classList.add("full-height");
      document.querySelector("body").classList.add("full-height");
      document.getElementById("root").classList.add("full-height");
      document.querySelector(".App").classList.add("full-height");
    }
  }

  return (
    <div
      style={{
        display: (hideRocketman.current && "none") || "flex",
      }}
    >
      <div className={"desktop-background " + (rocketmanTransport.retail ? ' retail ' : ' ')}></div>
      <div className={"container " + (rocketmanTransport.retail ? ' container-retail ' : ' ')}>
        <div className={"desktop-container " + (rocketmanTransport.retail ? ' retail ' : ' ')}>
          {rocketmanTransport?.isChatVisible ? 
          <button onClick={toggleClass} className="chat-expand-button">
          <ChatIconSvg />
          </button>
          : null}
          
          <div className={"desktop-left " + (rocketmanTransport.retail ? ' retail ' : ' ')}>
            <div className="desktop-section-title">
              {rocketmanTransport.retail ? <>Active Bets <RetailTotalBetsBadge /></> : translate("bet_statistic")}
            </div>
            <div className="position-relative desktop-left-height">
              <Bets />
              <div className="desktop-left-bottom-background"></div>
            </div>
          </div>
          <div className={"desktop-middle " + (isActive ? "active" : "")}>
            {rocketmanTransport.retail ? <RetailRoundId /> : null}
            <div
              className={"desktop-dialog " + (betInfoActive || betVerifyActive ? "active" : "")}
            >
              <div className="desktop-bet-info-holder">
                {betVerifyActive ? <BetVerify ticketRid={ticketRid} ticketTime={ticketTime} /> : null}
                {betInfoActive ? <BetInfo ticketId={ticketId} /> : null}
                {/* <BetVerify /> */}
              </div>
            </div>
            <PlayNow />
          </div>
          {rocketmanTransport?.isChatVisible ? 
          <div className={"desktop-right " + (isActive ? "opened" : "")}>
            <div className="desktop-right-content">
              <div className="desktop-section-title">
                {translate("chat_room")}
                <div className="d-flex">
                  <DesktopOnlinePlayers />
                  <button
                    onClick={() => setActive(false)}
                    className="close-container bet-info-close"
                  >
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                  </button>
                </div>
              </div>
              <Chat desktopActiveChatTab={isActive} />
              {/* <div className="desktop-right-bottom-background"></div> */}
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  );
}
