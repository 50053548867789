import React, { useState } from "react";
import { filterIsolatedBets } from "../../services/IsolatedBets/isolatedBets";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import AllBetsTr from "../AllBetsTr";

const AllBetsTab = (props) => {
  const PAGE_LENGTH = 50;
  const [listLength, setListLength] = useState(50);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const urlParams = new URLSearchParams(window.location.search);
  const sort = urlParams.get("sort");

  window.setAllBetsTabLang = setLanguage;

  return (
    <>
      <table className="bets-table">
        <thead>
          <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
            <th>{translate('user')}</th>
            <th>{translate('bet_bets_tab')}</th>
            <th>{translate('coeff')}</th>
            <th>{translate('win')}</th>
          </tr>
        </thead>
        <tbody>
          {sort == 'true' ? Object.values(props.bets)
            .slice(0, listLength)
            .sort((a,b) => Number(b.bet) - Number(a.bet))
            .map((bet, index) => {
              return filterIsolatedBets(bet) ? (
                <AllBetsTr
                  key={index}
                  avatar={`avatar${bet.avatar_id}`}
                  username={bet.username}
                  flagCode={bet.country_code == 'XK' && language === 'sr' ? 'rs' : bet.country_code}
                  bet={bet.bet}
                  coefficient={bet.win_eur && bet.bet_eur > 0 ? Number(bet.win_eur) / Number(bet.bet_eur) : Number(bet.win) / Number(bet.bet)}
                  win={bet.win ? bet.win : ""}
                  valute={rocketmanTransport.myCurrency}
                  isVip={bet.avatar_id > 49 ? true : false}
                />
              ) : null
            }) : Object.values(props.bets)
            .slice(0, listLength)
            .map((bet, index) => {
              return filterIsolatedBets(bet) ? (
                <AllBetsTr
                  key={index}
                  avatar={`avatar${bet.avatar_id}`}
                  username={bet.username}
                  flagCode={bet.country_code == 'XK' && language === 'sr' ? 'rs' : bet.country_code}
                  bet={bet.bet}
                  coefficient={bet.win_eur && bet.bet_eur > 0 ? Number(bet.win_eur) / Number(bet.bet_eur) : Number(bet.win) / Number(bet.bet)}
                  win={bet.win ? bet.win : ""}
                  valute={rocketmanTransport.myCurrency}
                  isVip={bet.avatar_id > 49 ? true : false}
                />
              ) : null
            })}
        </tbody>
      </table>
      {listLength < Object.values(props.bets).filter((bet) => filterIsolatedBets(bet)).length ? (
        <div className="load-more-button-holder">
          <button
            className={"primary-button " + (rocketmanTransport.r7css ? "r7css" : "")}
            onClick={() =>
              setListLength((oldListLength) => oldListLength + PAGE_LENGTH)
            }
          >
            {translate('load_more')}
          </button>
        </div>
      ): null}
    </>
  );
};

export default AllBetsTab;
