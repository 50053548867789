import * as React from "react";

function HowToPlaySvg(props) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm2 0a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm-9.318 2.045c0-1.47.54-2.099 1.773-2.715.71-.355.818-.482.818-1.171 0-.815-.625-1.296-1.728-1.296-.953 0-1.727.774-1.727 1.728H5.091a3.455 3.455 0 013.454-3.455C10.518 3.636 12 4.776 12 6.66c0 1.47-.54 2.099-1.773 2.716-.71.354-.818.48-.818 1.17H7.682zm.864 2.59a.864.864 0 100-1.728.864.864 0 000 1.728z"
        fill="#141945"
      />
    </svg>
  );
}

export default HowToPlaySvg;
