import moment from "moment";
import React, { useEffect, useState } from "react";


const Clock = () => {

  const [date, setDate] = useState(moment());

  useEffect(() => {

    var timeInterval = setInterval(() => {
      setDate(moment());
    },1000);


    return () => clearInterval(timeInterval);

  },[])
  
  

  return (
    <>
      {date.format('HH:mm:ss')}
    </>
  )
}

export default React.memo(Clock);
