import React, { useEffect, useState } from "react";
import rocketmanTransport from "../services/RocketmanTransport";
import AstronautPromoCredit from "./pop-ups/AstronautPromoCredit";
import AlienSaucerFreeBets from "./pop-ups/AlienSaucerFreeBets";
import translate from "../services/Translation/lang";
import ReactTooltip from "react-tooltip";
import UncreditedWins from "./UncreditedWins";
import Currency from "./Currency/Currency";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";

const HeaderBonusGames = (props) => {
  // const [promoCreditVal, setPromoCreditVal] = useState(0);
  const [showAstronautPromo, setShowAstronautPromo] = useState(false);
  const [showStartFreeBets, setShowStartFreeBets] = useState(false);
  const [spamFilter, setSpamFilter] = useState(false);
  const [showPromo, setShowPromo] = useState(false);

  // window.setPromoCreditNotif = setShowAstronautPromo;
  // window.setPromoCreditVal = setPromoCreditVal;

  const handleBalance = () => {
    if (!spamFilter) {
      rocketmanTransport.requestUserBalanceInAbstract();
      setSpamFilter(true);
      setTimeout(() => {
        setSpamFilter(false);
      }, 3000);
    } else return;
  };

  useEffect(() => {
    if (props.promoCredit >= rocketmanTransport.minBet) {
      setShowPromo(true);
    }
  }, [props.promoCredit]);

  return (
    <>
        <div className={"header-bonus-games " + props.className}>
          {props?.promoCredit && showPromo ? (
            <div
              data-tip={translate("promo_credit")}
              data-for="promoCredit"
              className="header-bonus-games-item"
            >
              {/* <CreditBonusSvg />{" "} */}
              <img
                width="20"
                src="/svg/blue-dolar.png"
                alt="blue-dolar"
                className="cursor-pointer"
                onClick={handleBalance}
              />
              <div className={"header-bonus-games-value " + (rocketmanTransport.r7css ? "r7css" : "")}>
                {currencyFormatter(props.promoCredit)}
                <Currency className={"header-bonus-games-currency " + (rocketmanTransport.r7css ? "r7css" : "")}>{rocketmanTransport.myCurrency}</Currency>
                {Number(props.minCashoutMultiplier) > 0
                  ? " @ x" + props.minCashoutMultiplier
                  : null}
              </div>
              <ReactTooltip
                className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                place="bottom"
                effect="solid"
                backgroundColor="#ffbf19"
                id="promoCredit"
              />
            </div>
          ) : null}
          {props?.betCount ? (
            <div
              data-tip={translate("free_bets_header")}
              data-for="bonusGames"
              className="header-bonus-games-item"
            >
              {props.showStartButton ? (
                <button
                  disabled={rocketmanTransport.showStartButton}
                  onClick={() => {
                    rocketmanTransport.startPlayFreeBets();
                  }}
                  className={
                    "header-bonus-games-start-button " +
                    (props.startBtnWaitForResponse ? "waiting" : "")
                  }
                >
                  start
                </button>
              ) : null}

              <div className={"header-bonus-games-value " + (rocketmanTransport.r7css ? "r7css" : "")}>
                {props.betCount} @<span>{currencyFormatter(props?.betMoney)}</span>{" "}
                <Currency>{rocketmanTransport.myCurrency}</Currency>
              </div>
              <ReactTooltip
                className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                place="bottom"
                effect="solid"
                backgroundColor="#ffbf19"
                id="bonusGames"
              />
            </div>
          ) : null}
          <UncreditedWins uncreditedWins={props.uncreditedWins} />
        </div>

      {/* <AstronautPromoCredit
        setShow={setShowAstronautPromo}
        show={showAstronautPromo}
        bonusCredit={props.promoCredit}
        promoText={translate("austronaut_promo_credit")}
      />
      <AlienSaucerFreeBets
        setShow={setShowStartFreeBets}
        setStartButton={props.setShowStartButton}
        show={showStartFreeBets}
        bonusCredit={props.betCount}
        bonusCash={props?.betMoney}
        promoText={translate("alien_saucer_free_bets")}
        startTheRound={true}
      /> */}
    </>
  );
};

export default HeaderBonusGames;
