import React, {useMemo} from "react";
import { useRocketmanTransport } from "../components/rocketman/RocketmanTransportContext";
import PlayNowSubComponent from "../components/PlayNow/PlayNowSubComponent";


export default function PlayNow() {

  const rocketmanContext = useRocketmanTransport();
  const secondBetButtonPlayed = rocketmanContext.bothButtonsActive;
  const gameState = rocketmanContext.gameState;
  const cashedOuts = rocketmanContext.cashedOuts;
  const myBetsByRound = rocketmanContext.myBetsByRound;
  const endedAndCashouted = rocketmanContext.endedAndCashouted;
  const currRound = rocketmanContext.currRound;
  const rockedSnapped = rocketmanContext.rockedSnapped;
  const multipliersHistory = rocketmanContext.multipliersHistory;
  const freeBetsCount = rocketmanContext.freeBetsCount;
  const freeBetsMoney = rocketmanContext.freeBetsMoney;
  const showBonusHeader = rocketmanContext.showBonusHeader;
  const promoBalance = rocketmanContext.promoBalance;
  const uncreditedWins = rocketmanContext.uncreditedWins;
  const allBets = rocketmanContext.allPlayerBets;
  const insuranceSnapped = rocketmanContext.insuranceSnapped;
  
  return useMemo(() => <PlayNowSubComponent allBets={allBets} insuranceSnapped={insuranceSnapped}  gameState={gameState} endedAndCashouted={endedAndCashouted} secondBetButtonPlayed={secondBetButtonPlayed} promoBalance={promoBalance} rockedSnapped={rockedSnapped} 
    cashedOuts={cashedOuts} myBetsByRound={myBetsByRound} currRound={currRound} multipliersHistory={multipliersHistory} freeBetsCount={freeBetsCount} 
      freeBetsMoney={freeBetsMoney} showBonusHeader={showBonusHeader} uncreditedWins={uncreditedWins} />, 
    [gameState,secondBetButtonPlayed, allBets, cashedOuts.length, myBetsByRound, promoBalance, uncreditedWins, endedAndCashouted, currRound, rockedSnapped, multipliersHistory.length,freeBetsCount,freeBetsMoney,showBonusHeader,insuranceSnapped]);
}
