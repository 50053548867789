import React, { useState } from 'react';
import Picker from "emoji-picker-react";

export default function EmojiPicker({setEmoji}) {

  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setEmoji(emojiObject);
  };

  return (
    <div>
      <Picker
        onEmojiClick={onEmojiClick}
        disableAutoFocus={true}
        groupNames={{ smileys_people: "PEOPLE" }}
        native
      />
    </div>
  )
}
