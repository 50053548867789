export const videoLinks = {
  en:	'yZ9q0OOgMjU',
  ar:	'xuDsMh_hWVw',
  bg:	'LUW5O52UShw',
  de:	'MXr9311A4Xc',
  el:	'jCrMusIwDhI',
  es:	'bRH5_dxa0Gc',
  fr:	'a43ovNVY5Xk',
  he:	'tqPi_SH0-lA',
  hr:	'aeG7TTpnEbI',
  hu:	'7ErN64gQ1Ig',
  it:	'1erlncypqz0',
  mk:	'JY-uvFgWWjE',
  pl:	'DybcYz_0xuU',
  pt:	'ZqMTXlhAXJg',
  ro:	'tlqEBTDB51s',
  sr:	'zrJI1z4fh3o',
  ru:	'UH8-utnLf8g',
  sq:	'Wh234xrP1yE',
  sv:	'GxKvhbqesQE',
  tr:	'AYLStlxdQ7w',
  uk:	'u6dZXo7TGcg',
  zh:'H26upSywVNw',
  xe: 'e7FsZ6fyYUw'
}
