import rocketmanTransport from "../RocketmanTransport";

export const filterIsolatedChatMsgs = (msg) => {
  // if there is no company id in msg show that message
  if (msg?.company_id === undefined) {
    return true;
  } else {
    // if there is company id and it belongs to player company show that message
    if (Number(msg.company_id) === Number(rocketmanTransport.launcherCompanyId) || Number(msg.company_id) === 0) {
      return true;
    }
  }
  // any other case do not show that message
  return false;
}
