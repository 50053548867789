import React from "react";

const BigTitle = (props) => {
  return (
    <h3 disabled={props.disabled} className={"big-title text-white " + props.className}>
      {props.title}
    </h3>
  );
};

export default BigTitle;
