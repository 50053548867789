import React from "react";
import PenSvg from "./svg/pen-svg";

const ChangeUsernameButton = (props) => {
  return (
    <button disabled={props.disabled} className="avatar-button">
      <PenSvg className="mr-2" />
      {props.title}
    </button>
  );
};

export default ChangeUsernameButton;
