import * as React from "react";
import { animated } from 'react-spring';

function FloatingCoinFirstSvg({style}) {
  return (
    <animated.svg
      width={30}
      height={29}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <ellipse
        rx={12.844}
        ry={5.708}
        transform="scale(-1 1) rotate(-40.64 14.799 26.152)"
        fill="#EF8C1A"
      />
      <path
        fill="#EF8C1A"
        d="M25.998 21.062L6.506 4.332 3.718 7.581 23.21 24.31z"
      />
      <ellipse
        rx={12.844}
        ry={5.708}
        transform="scale(-1 1) rotate(-40.64 9.017 28.293)"
        fill="#FFBF1A"
      />
    </animated.svg>
  );
}

export default FloatingCoinFirstSvg;
