import React, { useEffect, useState } from "react";
import PrimaryButton from "../components/PrimaryButton";
import BetInfoTableAvatar from "../components/BetInfoTableAvatar";
import SimpleBar from "simplebar-react";
import { useHistory, useLocation } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import "simplebar/dist/simplebar.min.css";
import translate from "../services/Translation/lang";
import rocketmanTransport from "../services/RocketmanTransport";
import moment from "moment";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import ReactTooltip from "react-tooltip";
import Currency from "../components/Currency/Currency";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const highLight = (mp) => {
  if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
  if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
  if (Number(mp) > 99.99) return "win-legendary";

  return "win-small";
}

export default function BetInfo({ ticketId }) {
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const history = useHistory();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const stateParams = new URLSearchParams(location.search);
  const ticketIdInfo = stateParams.get("ticketId") || ticketId;
  const [ticketDetails, setTicketDetails] = useState();

  const handleBetInfo = () => {
    if (version === "desktop" &&  width >= 1024) {
      window.setBetInfoActive(false);
    } else {
      history.goBack();
    }
  };

  window.setTicketDetails = setTicketDetails;

  useEffect(() => {
    rocketmanTransport.requestTicketDetails(ticketIdInfo);
  }, [ticketIdInfo]);

  return (
    <div className="page-holder">
      <ScrollToTopOnMount />
      <div className="container">
        <div className="bet-info-header">
          <div className="bet-info-header-left">
            <h4>Round {ticketDetails?.round_id}</h4>
          </div>
          <div className="bet-info-header-right">
            <button
              className="close-container bet-info-close"
              onClick={handleBetInfo}
            >
              <div className="leftright"></div>
              <div className="rightleft"></div>
            </button>
          </div>
        </div>
        <div>
          <SimpleBar className="desktop-bet-info">
            <div className="bet-info-table">
              <div className="bet-info-table-row">
                <div className="bet-info-td">
                  <p className="bet-info-table-title">
                    {translate("date_and_time")}:
                  </p>
                </div>
                {/* prevuci zastavu da ide prvo ona levo */}
                <div className="bet-info-td">
                  <p className="bet-info-time">
                    {moment(ticketDetails?.created_time).format(
                      "DD.MM.YY  hh:mm:ss"
                    )}
                  </p>
                </div>
              </div>
              <div className="bet-info-table-row">
                <div className="bet-info-td">
                  <p className="bet-info-table-title">
                    {translate("company")}:
                  </p>
                </div>
                <div className="bet-info-td">
                  <BetInfoTableAvatar
                    avatar=""
                    username={ticketDetails?.company_name}
                    flagCode={ticketDetails?.country_code}
                  />
                </div>
              </div>
              <div className="bet-info-table-row">
                <div className="bet-info-td">
                  <p className="bet-info-table-title">{translate("bet")}:</p>
                </div>
                <div className="bet-info-td">
                  <div className="bet-info-table-hash" data-tip={ticketDetails?.win ? Number(ticketDetails?.bet_eur).toFixed(rocketmanTransport.noOfDecimals) + " EUR" : ""}>
                    {currencyFormatter(ticketDetails?.bet)} <Currency>{ticketDetails?.currency_code}</Currency> 
                    <ReactTooltip
                      className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                      place="top"
                      effect="solid"
                      backgroundColor="#ffbf19"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bet-info-table">
              <div className="bet-info-table-row">
                <div className="bet-info-td">
                  <p className="bet-info-table-title">
                    {translate("multiplier")}:
                  </p>
                </div>
                <div className="bet-info-td">
                  <div 
                  className={ticketDetails?.win ? ["mmsb-info-content", highLight( ticketDetails?.mp)].join(" ") :"mmsb-info-content"} >
                    {ticketDetails?.win
                      ? Number(ticketDetails?.mp).toFixed(2) + "x"
                      : "---"}
                  </div>
                </div>
              </div>
              <div className="bet-info-table-row">
                <div className="bet-info-td">
                  <p className="bet-info-table-title">{translate("win")}:</p>
                </div>
                <div className="bet-info-td">
                  <div className="bet-info-table-hash" data-tip={ticketDetails?.win ? Number(ticketDetails?.win_eur).toFixed(rocketmanTransport.noOfDecimals) + " EUR" : ""}>
                    {ticketDetails?.win
                      ? currencyFormatter(Number(ticketDetails?.win))
                      : "---"}
                      <Currency> {ticketDetails?.currency_code}</Currency>
                    <ReactTooltip
                      className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                      place="top"
                      effect="solid"
                      backgroundColor="#ffbf19"
                    />
                  </div>
                </div>
              </div>
              <div className="bet-info-table-row">
                <div className="bet-info-td">
                  <p className="bet-info-table-title">
                    {translate("jackpot")}:
                  </p>
                </div>
                <div className="bet-info-td">
                  <div className="bet-info-table-hash" data-tip={ticketDetails?.jackpot_eur ? Number(ticketDetails?.jackpot_eur).toFixed(rocketmanTransport.noOfDecimals) + " EUR" : ""}>
                    {ticketDetails?.jackpot
                      ? currencyFormatter(Number(ticketDetails?.jackpot))
                      : "---"}
                      <Currency> {ticketDetails?.currency_code}</Currency>
                    <ReactTooltip
                      className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
                      place="top"
                      effect="solid"
                      backgroundColor="#ffbf19"
                    />
                  </div>
                </div>
              </div>
            </div>
            <PrimaryButton
              title={translate("close")}
              onClick={handleBetInfo}
            ></PrimaryButton>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}
