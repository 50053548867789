import React from "react";
import Modal from "react-bootstrap/Modal";
import TournamentWinnerImage from "../../img/tournament-first-big.png";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";
import Currency from "../Currency/Currency";

const TournamentWinner = ({ show, setShow, bonusCredit, promoText }) => {
  return (
    <Modal
      className="pop-up astronaut-popup tournament-winner-popup"
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      backdropClassName="over-popup-backdrop"
      enforceFocus={false}
    >
      <Modal.Body>
        <div className="astronaut-pop-up-body">
          <img style={{width: 200}} src={TournamentWinnerImage} alt="astronaut-promo-credit" />
          <div className="pop-up-promo-text">
            <div className="pop-up-promo-text-holder">
              <h1 className="pupt-vissible">{promoText}</h1>
              <h1 className="pupt-hidden">{promoText}</h1>
            </div>
          </div>
          <div className="pop-up-message-box">
            <div className="pop-up-promo-bonus">
              <div className="pop-up-promo-bonus-holder">
                <h1 className="pupb-vissible">+{bonusCredit}<Currency>{rocketmanTransport.myCurrency}</Currency></h1>
                <h1 className="pupb-hidden">+{bonusCredit}<Currency>{rocketmanTransport.myCurrency}</Currency></h1>
              </div>
            </div>
            <p className="pop-up-message-description">
              {/* {translate('promo_credit_condradulations')} */}
              Congratulations! You have won an Tournament credit that you can use in the game!
            </p>
          </div>
          <div className="pop-up-close">
            <PrimaryButton
              title={translate('ok_thanks')}
              onClick={() => setShow(false)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TournamentWinner;
