import React from "react";
import ProvablyFairSvg from "./svg/provably-fair-svg";
import ElbetLogoSvg from "./svg/elbet-logo-svg";
import translate from "../services/Translation/lang";

const ProvablyFairFooter = (props) => {
  return (
    <div className="provably-fair">
      <div className="container">
        <div className="provably-fair-left">
          <p>{translate('this_game_is')}</p> <ProvablyFairSvg className="mx-1" />{" "}
          <p className="bold">Provably Fair</p>
        </div>
        <div className="provably-fair-right">
          <p>{translate('powered_by')}</p>
          <ElbetLogoSvg width={26} height={8} className="ml-1" />
        </div>
      </div>
    </div>
  );
};

export default ProvablyFairFooter;
