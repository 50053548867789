import * as React from "react";

function MenuArrowDown(props) {
  return (
    <svg
      width={12}
      height={6}
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.22.375A1 1 0 011.624.22L6 3.72l4.375-3.5a1 1 0 011.25 1.562l-5 4a1 1 0 01-1.25 0l-5-4A1 1 0 01.22.375z"
        fill="#fff"
      />
    </svg>
  );
}

export default MenuArrowDown;
