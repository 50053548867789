import rocketmanTransport from "../RocketmanTransport";
import storage from "../Storage/Storage";

const SHARE_DELAY = 20000; // 10000 equals 10 sec
const MIN_COEF = 50;
const MIN_BET_EUR = 500;

var intervalStarted = false;
var storageIntervalStarted = storage.get('storageIntervalStarted');
var isAbleToShare = storage.get('isAbleToShare') === null ? true : storage.get('isAbleToShare');
var nextShareCountDown = storage.get('nextShareCountDown') || SHARE_DELAY;

const startCanShareInterval = () => {
  storage.set('storageIntervalStarted', true);
  var countDownInterval = setInterval(() => {
    nextShareCountDown -= 1000;
    storage.set('nextShareCountDown', nextShareCountDown);
    if (nextShareCountDown <= 0) {
      isAbleToShare = true;
      storage.set('isAbleToShare', true);
      nextShareCountDown = SHARE_DELAY;
      storage.set('nextShareCountDown', nextShareCountDown);
      clearInterval(countDownInterval);
      intervalStarted = false;
      storage.set('storageIntervalStarted',false);
    }
  }, 1000);
}

export const initializeShareIntervalAfterReloadTicket = () => {
  if (storageIntervalStarted) {
    startCanShareInterval();
  }
}

export const canShare = (coef, betWin) => {
  if (coef >= MIN_COEF || betWin >= MIN_BET_EUR) {
    if (!storage.get('storageIntervalStarted')) {
      console.log("SPAM INTERVAL NOT STARTED STARTING")
      startCanShareInterval();
      intervalStarted = true;
      storage.set('storageIntervalStarted',intervalStarted)
    }
    
    if (isAbleToShare) {
      isAbleToShare = false;
      storage.set('isAbleToShare', false);
      return true;
    } else {
      rocketmanTransport.localErrors("SPAM BLOCK")
    }
  } else {
    console.error("MIN COEF MUST BE AT LEAST 50 OR BET WIN ABOVE 500 EUR TO SHARE TICKET")
  }
  
  return false;
}

export const checkEachCanShare = (coef, betWin) => {
  if (coef >= MIN_COEF || betWin >= MIN_BET_EUR) {
    return true;
  }

  return false;
}
