import * as React from "react";
const FullscreenPlayNowFooter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <title />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M2 9H0v5h5v-2H2V9ZM0 5h2V2h3V0H0v5Zm12 7H9v2h5V9h-2v3ZM9 0v2h3v3h2V0H9Z"
    />
  </svg>
);
export default FullscreenPlayNowFooter;
