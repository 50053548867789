export function handleRank(rank) {
  switch (true) {
    case rank === 1:
      return "tournament-first";
    case rank === 2:
      return "tournament-second";
    case rank === 3:
      return "tournament-third";
    case rank >= 4 && rank <= 10:
      return "prize-4th-10th";
    case rank >= 11 && rank <= 20:
      return "prize-11th-20th";
    case rank >= 21 && rank <= 30:
      return "prize-21th-30th";
    case rank >= 31 && rank <= 40:
      return "prize-31th-40th";
    case rank >= 41 && rank <= 50:
      return "prize-41th-50th";
    case rank >= 51 && rank <= 60:
      return "prize-51th-60th";
    default:
      return "over-60th";
  }
}
