import React from "react";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import translate from "../../services/Translation/lang";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sticky from "react-sticky-el";
import AllBetsTab from "../bets-tabs/AllBetsTab";
import PreviousHandTab from "../bets-tabs/PreviousHandTab";
import { filterIsolatedBets } from "../../services/IsolatedBets/isolatedBets";
import rocketmanTransport from "../../services/RocketmanTransport";
import AllShopsTab from "../bets-tabs/AllShopsTab";

export default function AllBetsSubComponent({
  multipliersHistory,
  allBets,
  currRound,
  avatarId,
}) {

  const totalBets =  Object.values(allBets).filter((bet) => bet.round_id === currRound && filterIsolatedBets(bet)).length;

  return (
    <Tabs
      selectedTabClassName="selected-pod-tab"
      onSelect={() => {
        soundFxPlayer.play("slider");
      }}
    >
      <Sticky
        topOffset={-74}
        className={"sticky-header-holder " + (rocketmanTransport.retail ? ' retail ' : ' ')}
        stickyClassName="sticky-header"
      >
        <header>
          <TabList className={"pod-tabs-nav " + (rocketmanTransport.retail ? ' retail ' : ' ')}>
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>
              {translate("total_bets")}
              <div className={"pod-tabs-nav-badge " + (rocketmanTransport.r7css ? "r7css" : "")}>
                {totalBets}
              </div>
            </Tab>
            <Tab className={"pod-tabs-nav-item " + (rocketmanTransport.r7css ? "r7css" : "")}>
              {translate("previous_hand")}
              <div className={"pod-tabs-nav-badge " + (rocketmanTransport.r7css ? "r7css" : "")}>
                {multipliersHistory.length
                  ? multipliersHistory.slice(-1)[0] + "x"
                  : ""}
              </div>
            </Tab>
          </TabList>
        </header>
      </Sticky>

      <div className="pod-tabs-panel-holder container">
        <TabPanel>
          {!rocketmanTransport.retail ? (
            <AllBetsTab
              bets={Object.values(allBets).filter(
                (bet) => bet.round_id === currRound
              )}
              avatarId={avatarId}
            />
          ) : (
            <AllShopsTab />
          )}
          
        </TabPanel>
        <TabPanel>
          <PreviousHandTab
            bets={Object.values(allBets).filter(
              (bet) => bet.round_id === currRound - 1
            )}
            avatarId={avatarId}
          />
        </TabPanel>
      </div>
    </Tabs>
  );
}
