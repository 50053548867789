import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import WaitingSvg from "../svg/waiting-svg";
import translate from '../../services/Translation/lang';
import rocketmanTransport from '../../services/RocketmanTransport';

export default function WaitingForNextRound() {

  const OFFSET = 74;

  return (
    <div className="wftnr-holder">
      <ScrollAnimation
        animateOnce
        offset={OFFSET}
        delay={200}
        duration={1}
        animateIn="fadeInUp"
      >
        <div className={"wftnr-text " + (rocketmanTransport.r7css ? "r7css" : "")}>
          <p>
            <WaitingSvg className="mr-2" /> { translate('waiting_for_the_next_round') }
          </p>
        </div>
      </ScrollAnimation>
      <ScrollAnimation
        animateOnce
        offset={OFFSET}
        delay={100}
        duration={1}
        animateIn="fadeInUp"
      >
        <div className="wftnr-line"></div>
      </ScrollAnimation>
    </div>
  )
}
