import React, { useState} from "react";
import RetailPlanet1 from "../../img/retail-slider/retail-planet-1/planet-1.png";
import RetailPlanet2 from "../../img/retail-slider/retail-planet-2/planet-2.png";
import RetailSateliteGroup1 from "../../img/retail-slider/retail-planet-1/satelite-group-1.png";
import RetailSateliteGroup2 from "../../img/retail-slider/retail-planet-2/satelite-group-2.png";
import RetailSatelite1 from "../../img/retail-slider/retail-planet-1/satelite-1.png";
import RetailSatelite2 from "../../img/retail-slider/retail-planet-1/satelite-2.png";
import RocketmanSlider from './RocketmanSlider.js'
import GameExplanation from './GameExplanation.js'
import MaxMultiplier from './MaxMultiplier.js'
import MultiplierHistory from './MultiplierHistory.js'
import BonusLaunch from './BonusLaunch.js'
import CountdownSlider from './CountdownSlider.js'
import Multibet from "./Multibet.js";

export default function RetailSlider() {
  const [showScreen, setShowScreen] = useState("rocketman");

  window.setShowScreen = (screen) => {
    if (showScreen !== screen) {
      setShowScreen(screen);
    }
  };

  const Components = {
    rocketman: <RocketmanSlider />,
    game_explanation: <GameExplanation />,
    max_multiplier: <MaxMultiplier />,
    multibet: <Multibet />,
    bonus_launch: <BonusLaunch />,
    multiplier_history: <MultiplierHistory />,
    countdown_slider: <CountdownSlider />,
    default: null,
  };

  const renderScreen = () => {
    if (showScreen) {
      switch (showScreen) {
        case "Rocketman_logo":
          return "rocketman";
        case "Game_explanation":
          return "game_explanation";
        case "Max_multiplier":
          return "max_multiplier";
        case "Multibet":
          return "multibet";
        case "Bonus_launch":
          return "bonus_launch";
        case "Multiplier_history":
          return "multiplier_history";
        case "Countdown":
          return "countdown_slider";
        default:
          return "default";
      }
    }
  };

  return (
    <div className="retail-slider-container">
        {renderScreen() !== 'default' ? <div className="retail-slider-background">
          <img
            className="retail-planet-satelite-group-1"
            src={RetailSateliteGroup1}
            alt="retail-planet-satelite-group-1"
          />
          <div className="retail-planet retail-planet-1">
            <img
              className="retail-planet-satelite-1"
              src={RetailSatelite1}
              alt="retail-planet-satelite-1"
            />
            <img
              className="retail-planet-satelite-2"
              src={RetailSatelite2}
              alt="retail-planet-satelite-2"
            />
            <img
              className="retail-planet-main"
              src={RetailPlanet1}
              alt="retail-planet-1"
            />
          </div>
          <img
            className="retail-planet-satelite-group-2"
            src={RetailSateliteGroup2}
            alt="retail-planet-satelite-group-2"
          />
          <div className="retail-planet retail-planet-2">
            <img
              className="retail-planet-satelite-1"
              src={RetailSatelite1}
              alt="retail-planet-satelite-1"
            />
            <img
              className="retail-planet-satelite-2"
              src={RetailSatelite2}
              alt="retail-planet-satelite-2"
            />
            <img
              className="retail-planet-main"
              src={RetailPlanet2}
              alt="retail-planet-1"
            />
          </div>
          {Components[renderScreen()]}
        </div> : Components[renderScreen()]}
    </div>
  );
}
