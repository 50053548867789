import React, { useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import OdometerComp from "../OdometerComp";
import rocketmanTransport from "../../services/RocketmanTransport";
import AutoCashout from "../BetComponent/AutoCashout";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import translate from "../../services/Translation/lang";
import { useHotkeys } from "react-hotkeys-hook";
import AdvancedAutoPlay from "../pop-ups/AdvancedAutoPlay";
import AdvancedAutoplayPreviewArrow from "../../img/advanced-autoplay-preview-arrow.png";
import { commaToDot } from "../../services/Helpers/CommaToDot";
import Currency from "../Currency/Currency";
import { NumericFormat } from 'react-number-format';
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import { clearDotsAndCommas } from "../../services/Helpers/StringHelper";
import ProvablyFairSvg from "../svg/provably-fair-svg";

const INPUT_REGX = /^(20000|[0-9]{1,5}([.,]\d{0,2})?|0([.,]0{1,2})?)$/;

const MAX_CASHOUT_VAL = 20000;
const MIN_CASHOUT_VAL = 1.01;
const BUY_DISABLE_TIMEOUT = 1000;

export default function Button0(props) {
  const [max, setMax] = useState(100);
  const [min, setMin] = useState(0);
  const [predefinedBets, setPredefinedBets] = useState(
    rocketmanTransport.predefinedBets
  );
  const [fixedBet, setFixedBet] = useState(rocketmanTransport.fixedBet);
  const [disabled, setDisabled] = useState(false);
  const [keyboardBuyDisabled, setKeyboardBuyDisabled] = useState(false);
  const [keyboard1BuyDisabled, setKeyboard1BuyDisabled] = useState(false);
  const [dissalowBets, setDissalowBets] = useState(false);
  const [showAdvancedAutoPlay, setShowAdvancedAutoPlay] = useState(false);
  // const [showAdvancedAutoplayPromoButton, setShowAdvancedAutoplayPromoButton] = useState(false)
  const [button0Ballance, setButton0Balance] = useState(0);
  const [disabledCashout, setDisabledCashout] = useState(false);
  const [balanceDiffRerender,setForceBallanceDiffRerender] = useState(false);
  const [useBetInsurance, setBetInsurance] = useState(storage.get('use-bet-insurance-0') || 0);

  const autoBet = Boolean(rocketmanTransport.launcherAutoBet);
  const autoCashout = rocketmanTransport.launcherAutCashout;

  const buttonRefs = useRef([]);

  const handleClick = (predefinedBet, index) => {
    setButton0Params((prevState) => ({
      ...prevState,
      val: predefinedBet,
    }));
    soundFxPlayer.play("increase_amount_BTN");

    // Scroll to the clicked button
    buttonRefs.current[index].scrollIntoView({
      behavior: "smooth",
      inline: "center", // aligns button to the center of horizontal scroll
      block: "nearest", // ensures horizontal scroll behavior
    });
  };

  const [button0Params, setButton0Params] = useState({
    autoBet: false,
    autoCashout: "",
    ticketId: null,
    roundId: null,
    val: (storage.get('lastBetValue0') && storage.get('lastBetCurrency') === rocketmanTransport.myCurrency) ? storage.get('lastBetValue0') : rocketmanTransport.defBet.toFixed(rocketmanTransport.noOfDecimals),
    minCashoutMultiplier: "",
    buttonBusy: false,
    gameState: "initialState",
  });

  const initalRender = useRef(true);

  var disableBuy;
  var disableBuyKeyboard;
  var disableBuyKeyboard1;

  useEffect(() => {
    storage.set('use-bet-insurance-0', Number(useBetInsurance))
  },[useBetInsurance]);

  useEffect(() => {
    if (initalRender.current && autoBet && rocketmanTransport.demoAutoBet) {
      setDisabled(true);
      rocketmanTransport.localErrors("AUTO BET WILL INITIATE SOON ...");
      rocketmanTransport.setDemoBetAlreadyActivated(false);
      initalRender.current = false;
      const buyTicketTimeout = setTimeout(() => {
        setButton0Params((prevState) => ({
          ...prevState,
          autoBet,
          autoCashout,
        }));
        rocketmanTransport.bet(button0Params.val, autoCashout, autoBet, 0);
        clearTimeout(buyTicketTimeout);
        setDisabled(false);
      }, 5000);
    }
  }, [button0Params, autoBet, autoCashout]);

  useEffect(() => {
    return () => {
      clearTimeout(disableBuy);
      clearTimeout(disableBuyKeyboard);
      clearTimeout(disableBuyKeyboard1);
    };
  }, [disableBuy, disableBuyKeyboard, disableBuyKeyboard1]);

  useEffect(() => {
    setMax(rocketmanTransport.maxBet)
  }, [rocketmanTransport.maxBet]);

  const buttonValRef = useRef();
  const autoCashoutRef = useRef();

  const placeKeyboardBet = () => {
    setKeyboardBuyDisabled(true);
    disableBuyKeyboard = setTimeout(() => {
      setKeyboardBuyDisabled(false);
      clearTimeout(disableBuyKeyboard);
    }, BUY_DISABLE_TIMEOUT);
  };

  const placeKeyboard1Bet = () => {
    setKeyboard1BuyDisabled(true);
    disableBuyKeyboard1 = setTimeout(() => {
      setKeyboard1BuyDisabled(false);
      clearTimeout(disableBuyKeyboard1);
    }, BUY_DISABLE_TIMEOUT);
  };

  // On b key pressed try to buy
  useHotkeys(
    "b",
    () => {
      if (!button0Params.buttonBusy) {
        if (!keyboardBuyDisabled) {
          placeKeyboardBet();
          rocketmanTransport.bet(
            button0Params.val,
            button0Params.autoCashout,
            button0Params.autoBet,
            0
          );
        }
      } else {
        if (!keyboard1BuyDisabled) {
          placeKeyboard1Bet();
          window.betFirstButtonByKey();
        }
      }
    },
    [
      button0Params.val,
      button0Params.buttonBusy,
      keyboardBuyDisabled,
      keyboard1BuyDisabled,
    ]
  );

  // On c key pressed try to Cancel  bet or Cashout
  useHotkeys(
    "c",
    () => {
      if (button0Params.buttonBusy) {
        if (button0Params.gameState === "betActive") {
          window.cancelOrCashoutFirstButton();
        } else {
          rocketmanTransport.cashoutBet(
            button0Params.autoCashout ? button0Params.autoCashout : false,
            0,
            button0Params.ticketId,
            button0Params.autoBet
          );
        }
      } else {
        window.cancelOrCashoutFirstButton();
      }
    },
    [
      button0Params.autoBet,
      button0Params.autoCashout,
      button0Params.ticketId,
      button0Params.buttonBusy,
    ]
  );

  // window.setShowAdvancedAutoplayPromoButton = setShowAdvancedAutoplayPromoButton;

  window.setButton0MaxBetVal = (val) => {
    setButton0Params((prevState) => ({
    ...prevState,
    val
  }))};

  window.canceZeroIndexButton = () => {
    if (button0Params.buttonBusy) {
      if (button0Params.gameState === "betActive") {
        handleCancelBet(button0Params.ticketId, 0);
      } else {
        rocketmanTransport.cashoutBet(
          button0Params.autoCashout ? button0Params.autoCashout : false,
          0,
          button0Params.ticketId,
          button0Params.autoBet
        );
      }
    }
  };

  window.setMax0 = (val) => {
    if (val) setMax(val);
  };
  window.setMin0 = setMin;
  window.toggleButton0AutoBet = (val) => {
    setButton0Params((prevState) => ({
      ...prevState,
      autoBet: val,
    }));
  };
  window.setPredefinedBets0 = setPredefinedBets;
  window.setFixedBet0 = setFixedBet;
  window.forceButton0BallanceDiffRerender = () => {
    setForceBallanceDiffRerender(!balanceDiffRerender);
  }

  const handleChangeAutoBet = (nextChecked, off = false) => {
    soundFxPlayer.play("menu_switch_1");
    if (rocketmanTransport.buttons[0]) {
      rocketmanTransport.buttons[0].autoBet = off;
      rocketmanTransport.toggleAutoBet(
        0,
        button0Params.ticketId,
        button0Params.autoBet
      );
    }
    setButton0Params((prevState) => ({
      ...prevState,
      autoBet: off,
    }));
  };

  window.handleCHangeAutoBet0 = handleChangeAutoBet;
  window.setButton0Params = setButton0Params;
  window.setDissalowBets0 = setDissalowBets;
  window.setButton0Balance = setButton0Balance;

  const onValueBlur = (e) => {
    // if (Number(e.target.value) > max) e.target.value = max;
    // //if (Number(e.target.value) < min) e.target.value = min;
    // setButton0Params((prevState) => ({
    //   ...prevState,
    //   val: e.target.value,
    // }));
  };

  const placeBet = (advanced = false) => {
    if(button0Params.val >= min && button0Params.val <= max && button0Params.val <= rocketmanTransport.balance) {
      storage.set('lastBetValue0', button0Params.val)
      storage.set('lastBetCurrency', rocketmanTransport.myCurrency)
    }
    setDisabled(true);
    disableBuy = setTimeout(() => {
      setDisabled(false);
      clearTimeout(disableBuy);
    }, BUY_DISABLE_TIMEOUT);

    rocketmanTransport.bet(
      button0Params.val,
      button0Params.autoCashout,
      button0Params.autoBet,
      0,
      advanced
    );
  };

 const betInputRef = useRef(null);

  const handleValueChange = (values, sourceInfo) => {
      const { floatValue } = values;

      if (floatValue > max) {
        setButton0Params((prevState) => ({
          ...prevState,
          val: max,
        }));
      } else {
        setButton0Params((prevState) => ({
          ...prevState,
          val: floatValue,
        }));
      }
  }

  const handleIsAllowed = (values) => {

    const { floatValue } = values;

    if (floatValue < 1) {
         setButton0Params((prevState) => ({
        ...prevState,
        val: 1,
      }));
    } else if (floatValue > max) {
         setButton0Params((prevState) => ({
        ...prevState,
        val: max,
      }));
    } 

    return floatValue >= 1 && floatValue <= max;
  }
  
  
  const handleCancelBet = (ticketId, buttonIndex) => {
    rocketmanTransport.cancelTicket(ticketId, buttonIndex, button0Params.val);
    // props.cancelBet();
  };

  const increase = () => {
    if (fixedBet.length > 0) {
      const currentValue = Number(button0Params.val);
  
      let nextHigherValue = fixedBet[fixedBet.length - 1];
      for (let i = fixedBet.length - 1; i >= 0; i--) {
        if (currentValue < fixedBet[i]) {
          nextHigherValue = fixedBet[i];
        } else {
          break;
        }
      }
  
      soundFxPlayer.play("increase_amount_BTN");
      setButton0Params((prevState) => ({
        ...prevState,
        val: nextHigherValue.toFixed(rocketmanTransport.noOfDecimals),
      }));
    } else {
      if (Number(button0Params.val) + Number(rocketmanTransport.defBet) <= max) {
        soundFxPlayer.play("increase_amount_BTN");
        setButton0Params((prevState) => ({
          ...prevState,
          val: (
            Number(prevState.val) + Number(rocketmanTransport.defBet)
          ).toFixed(rocketmanTransport.noOfDecimals),
        }));
      }
    }
  };
  
  const decrease = () => {
    if (fixedBet.length > 0) {
      const currentValue = Number(button0Params.val);
  
      let nextLowerValue = fixedBet[0];
      for (let i = 0; i < fixedBet.length; i++) {
        if (currentValue > fixedBet[i]) {
          nextLowerValue = fixedBet[i];
        } else {
          break;
        }
      }
  
      soundFxPlayer.play("decrease_amount_BTN");
      setButton0Params((prevState) => ({
        ...prevState,
        val: nextLowerValue.toFixed(rocketmanTransport.noOfDecimals),
      }));
    } else {
      if (Number(button0Params.val) - Number(rocketmanTransport.defBet) >= min) {
        soundFxPlayer.play("decrease_amount_BTN");
        setButton0Params((prevState) => ({
          ...prevState,
          val: (
            Number(prevState.val) - Number(rocketmanTransport.defBet)
          ).toFixed(rocketmanTransport.noOfDecimals),
        }));
      } else {
        soundFxPlayer.play("decrease_amount_BTN");
        setButton0Params((prevState) => ({
          ...prevState,
          val: min.toFixed(rocketmanTransport.noOfDecimals),
        }));
      }
    }
  };

  const checkMinAutoCashout = () => {
    if (Number(button0Params.autoCashout) === 1) {
      if (rocketmanTransport.buttons[0]) {
        rocketmanTransport.buttons[0].autoCashout = MIN_CASHOUT_VAL;
      }
      setButton0Params((prevState) => ({
        ...prevState,
        autoCashout: MIN_CASHOUT_VAL,
      }));
    }
  };

  const setAutoCashValue = (val) => {
    if (val) {
      if (INPUT_REGX.test(val)) {
        const convertedVal = commaToDot(val);
        if (Number(convertedVal) > MAX_CASHOUT_VAL) return;
        if (Number(convertedVal) < 1) return;
        if (rocketmanTransport.buttons[0]) {
          rocketmanTransport.buttons[0].autoCashout = convertedVal;
        }
        setButton0Params((prevState) => ({
          ...prevState,
          autoCashout: convertedVal,
        }));
        soundFxPlayer.play("set_auto_cashout");
      } else {
        autoCashoutRef.current.blur();
      }
    } else {
      if (rocketmanTransport.buttons[0]) {
        rocketmanTransport.buttons[0].autoCashout = "";
      }
      setButton0Params((prevState) => ({
        ...prevState,
        autoCashout: "",
      }));
      soundFxPlayer.play("set_auto_click_sound");
    }
  };

  const handleInProgressTurnOffAdvancedAutoPlay = () => {
    rocketmanTransport.setAdvancedAutoPlay(0,false, button0Params.val);
    handleChangeAutoBet(null, false);
  }

  const handleInBetActiveTurnOffAdvancedAutoPlay = () => {
    handleCancelBet(button0Params.ticketId, 0);
    rocketmanTransport.setAdvancedAutoPlay(0,false, button0Params.val);
    handleChangeAutoBet(null, false);
  }

  return (
    <div className="play-now-component">
      {rocketmanTransport.advancedAutoplayInProgress[0].inProgress ? (
        <p className="advanced-autoplay-text">
          {translate("autoplay")}{" "}
          <span>{rocketmanTransport.advancedRoundsPlayed[0].played}</span>{" "}
          {translate("rounds")}{" "}
          <span>
            {rocketmanTransport.advancedBalanceDiff[0].balance.toFixed(
              rocketmanTransport.noOfDecimals
            )}
          </span>{" "}
          <Currency>{rocketmanTransport.myCurrency}</Currency>
        </p>
      ) : null}
      {useMemo(() => (
        <AdvancedAutoPlay
          show={showAdvancedAutoPlay}
          setShowAdvancedAutoPlay={setShowAdvancedAutoPlay}
          decrease={decrease}
          increase={increase}
          placeBet={placeBet}
          handleCancelBet={handleCancelBet}
          onValueChange={handleValueChange}
          isAllowed={handleIsAllowed}
          handleChangeAutoBet={handleChangeAutoBet}
          onValueBlur={onValueBlur}
          buttonParams={button0Params}
          buttonIndex={0}
          currRound={props.currRound}
          setAutoCashValue={setAutoCashValue}
          //autoCashoutRef={autoCashoutRef}
          checkMinAutoCashout={checkMinAutoCashout}
          button0Ballance={button0Ballance}
          setDisabledCashout={setDisabledCashout}
        />
      ),[showAdvancedAutoPlay,button0Params,button0Ballance,props.currRound])}
      <div className={"play-now-top " + (props.isActive ? "active" : "")}>
        <div
          className={
            "play-now-number-input " +
            (button0Params.gameState === "betInProgress" ? "in-progress" : "") + (clearDotsAndCommas(betInputRef?.current?.value)?.length >= 7 ? " zoom-out" : " zoom-in")
          }
        >
          <button
            disabled={
              button0Params.gameState === "betActive" ||
              rocketmanTransport.advancedAutoplayInProgress[0].inProgress
                ? true
                : false
            }
            onClick={
              button0Params.gameState === "betActive" ? () => null : decrease
            }
            className="play-now-number-input-decrese"
          >
            <div className="minus"></div>
          </button>
          <NumericFormat disabled={button0Params.gameState === "betActive" || fixedBet.length > 0}
            valueIsNumericString={true}
            value={button0Params.val}
            getInputRef={(el) => (betInputRef.current = el)}
            thousandSeparator=","
            decimalSeparator="."
            allowNegative={false}
            decimalScale={rocketmanTransport.noOfDecimals || 2}
            onValueChange={handleValueChange}
            isAllowed={handleIsAllowed}
            // fixedDecimalScale={rocketmanTransport.noOfDecimals > 0 ? true : false}
            />
          <button
            disabled={
              button0Params.gameState === "betActive" ||
              rocketmanTransport.advancedAutoplayInProgress[0].inProgress
                ? true
                : false
            }
            onClick={
              button0Params.gameState === "betActive" ? () => null : increase
            }
            className="play-now-number-input-increse"
          >
            <div className="plus"></div>
          </button>
        </div>

        <button
          disabled={
            (button0Params.gameState === "betActive" &&
              button0Params?.ticketType === 3) ||
            (button0Params.gameState === "betActive" &&
              Number(button0Params?.ticketType) === 2) ||
            (button0Params.gameState === "initialState" && disabled) ||
            (button0Params.gameState === "initialState" && dissalowBets) ||
               disabledCashout || (button0Params.gameState === "betActive" && rocketmanTransport.cancelButtonDisabled === true)
          }
          className={
            "bet-button " +
            (button0Params.gameState === "betActive"
              ? "bet-button-cancel"
              : button0Params.gameState === "betInProgress"
              ? "bet-button-in-progress"
              : "")
          }
          onClick={() =>
            !button0Params.buttonBusy
              ? placeBet()
              : button0Params.gameState === "betActive"
              ? handleCancelBet(button0Params.ticketId, 0)
              : rocketmanTransport.cashoutBet(
                  button0Params.autoCashout ? button0Params.autoCashout : false,
                  0,
                  button0Params.ticketId,
                  button0Params.autoBet
                )
          }
        >
          {button0Params.gameState === "betActive" ? (
            <span>{translate("cancel")}</span>
          ) : button0Params.gameState === "betInProgress" ? (
            <div className="cashout-button-holder">
              <span>{translate('cashout')}{" "}</span>&nbsp;{button0Params.minCashoutMultiplier > 0 ? "@ " + button0Params.minCashoutMultiplier : null}
              <span className="cashout-button-odometer">
                +<OdometerComp betAmount={button0Params.val} />{" "}
                <Currency className="odometer-currency "> {rocketmanTransport.myCurrency}</Currency>
              </span>
            </div>
          ) : (
            <span>{translate("bet")}</span>
          )}
          {
            button0Params?.betInsurance && button0Params.ticketType == 1 ? (
            <div className="bet-insurance-protected-wrapper">
              <div className="bip-holder"><ProvablyFairSvg width={14} height={17}  /></div>
            </div>) : null
          }
        </button>
      </div>
      {
        rocketmanTransport.useInsurance && button0Params.gameState !== 'betInProgress' ? (
        <div className="use-insurance">
          <label className="control control--checkbox">
          <input type="checkbox" checked={useBetInsurance} onChange={() => setBetInsurance(!useBetInsurance)} disabled={button0Params.gameState === 'betActive'} />
          <div className="control__indicator"></div>
            {translate('bet_insurance')}
          </label>
        </div>) : null
      }
      <div
        className={
          "play-now-middle " +
          (button0Params.gameState === "betActive"
            ? "active"
            : button0Params.gameState === "betInProgress"
            ? "in-progress"
            : props.isActive
            ? "in-progress"
            : "")
        }
      >
        {predefinedBets.map((predefinedBet, index) => {
          return (
            <div className="play-now-middle-button-holder" key={index}>
              <button
                className="play-now-middle-button"
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={() => handleClick(predefinedBet, index)}
              >
                {Intl.NumberFormat("en-US", {
                        maximumFractionDigits: rocketmanTransport.noOfDecimals,
                        minimumFractionDigits: rocketmanTransport.noOfDecimals,
                      }).format(predefinedBet)} <Currency className="ml-1">{rocketmanTransport.myCurrency}</Currency>
              </button>
            </div>
          );
        })}
      </div>
      <div
        className={
          "play-now-bottom " +
          (props.isActive
            ? "active"
            : button0Params.gameState === "betInProgress"
            ? "in-progress"
            : "")
        }
      >
        <AutoCashout
          advancedAutoPlay={
            rocketmanTransport.advancedAutoplayInProgress[0].inProgress || button0Params.gameState !== "initialState"
          }
          betValue={button0Params.val}
          cashoutText={translate("set_auto_cashout")}
          gameState={button0Params.gameState}
          setAutoCashValue={setAutoCashValue}
          autoBet={button0Params.autoBet}
          checkMinAutoCashout={checkMinAutoCashout}
          autoCashValue={button0Params.autoCashout}
          currentBet={button0Params.val}
          buttonIndex={0}
          ticketId={button0Params.ticketId}
          innerRef={autoCashoutRef}
          ticketType={button0Params.ticketType} 
          minCashoutMultiplier={button0Params.minCashoutMultiplier}
          setDisabledCashout={setDisabledCashout}
        />
          
        <button
          onClick={() => setShowAdvancedAutoPlay(!showAdvancedAutoPlay)}
          className={
            "advanced-autoplay-button " +
            (button0Params.gameState === "betInProgress " ? "in-progress " : " ") + 
            // (showAdvancedAutoplayPromoButton === true ? " highlighted " : " ") +
            (rocketmanTransport.r7css ? "r7css" : "")
          }
        >
        {/* {showAdvancedAutoplayPromoButton === true ? <img className="aap-arrow" src={AdvancedAutoplayPreviewArrow} alt="astronaut-promo-credit" /> : null} */}

          {translate("advanced_autoplay")}
        </button>
        {rocketmanTransport.advancedAutoplayInProgress[0].inProgress ? 
          (<button 
            onClick={() => 
              button0Params.gameState === "betActive"
              ? handleInBetActiveTurnOffAdvancedAutoPlay() :
              handleInProgressTurnOffAdvancedAutoPlay()
              }
            className={"advanced-autoplay-button stop-autoplay " + (rocketmanTransport.r7css ? "r7css" : "")} >
            {translate("stop_autoplay")}
          </button>) : null}
        <div
          className={
            "auto-cashout-holder-preview " +
            (button0Params.gameState === "betInProgress " ? "in-progress " : "") +
            (rocketmanTransport.r7css ? "r7css" : "")
          }
        >
          <p>{translate("bet_amount")}</p>
          <input disabled className="auto-cashout" value={currencyFormatter(button0Params.val)} />
        </div>
        {button0Params.gameState === "betInProgress" &&
        !button0Params.autoCashout ? null : (
          <div
            className={
              "auto-cashout-holder-preview " +
              (button0Params.gameState === "betInProgress " ? "in-progress " : "") +
              (rocketmanTransport.r7css ? "r7css" : "")
            }
          >
            <p>{translate("cashout_at")}</p>
            <input
              disabled
              className="auto-cashout"
              value={button0Params.autoCashout ? button0Params.autoCashout : ""}
            />
          </div>
        )}
        {!rocketmanTransport.advancedAutoplayInProgress[0].inProgress ? button0Params.gameState === "betInProgress" &&
        !button0Params.autoBet ? null : (
          <div className={"auto-cashout-toogle-holder " + (rocketmanTransport.r7css ? "r7css" : "")}>
            <p>{translate("set_auto_bet")}</p>
            <Switch
              disabled={
                button0Params.ticketType === 3 ||
                rocketmanTransport.advancedAutoplayInProgress[0].inProgress
              }
              onChange={() => handleChangeAutoBet(null, !button0Params.autoBet)}
              checked={button0Params.autoBet}
              className={
                button0Params.autoBet
                  ? "move-left react-switch react-switch-custom"
                  : "react-switch react-switch-custom"
              }
              onColor="#202558"
              offColor="#202558"
              borderRadius={28}
              // width={100}
              height={32}
              // handleDiameter={28}
              activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
              uncheckedIcon={
                <div className={"switcher-on-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("on")}</div>
              }
              checkedIcon={
                <div className={"switcher-off-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("off")}</div>
              }
              uncheckedHandleIcon={
                <div className={"switcher-off " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("off")}</div>
              }
              checkedHandleIcon={
                <div className={"switcher-on " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("on")}</div>
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
