import React, { useState, useEffect, useRef } from "react";
import ArrowLeftButtonSvg from "../../svg/arrow-left-button-svg";
import Won from "./Won";
import Loss from "./Loss";
import translate from "../../../services/Translation/lang";

export default function GifsPicker({submitGif}) {
  const [category, setCategory] = useState("");

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    //executeScroll();
  }, [category]);

  return (
    <div className="chat-gifs-holder">
      <div style={{ position: "absolute", top: 0 }} ref={myRef}></div>
      <div className="chat-gifs-title">
        {category === "win" || category === "loss" ? (
          <div onClick={() => setCategory("")}>
            <ArrowLeftButtonSvg className="mr-1" />
          </div>
        ) : null}
        {category === "win" ? translate('win_gifs') : category === "loss" ? translate('loss_gifs') : translate('gifs')}
      </div>
      {category === "win" ? (
        <Won submitGif={submitGif} />
      ) : category === "loss" ? (
        <Loss submitGif={submitGif} />
      ) : (
        <div className="chat-gifs-container">
          <div className="chat-gifs-category">
            <h6
              onClick={() => setCategory("win")}
              className="chat-gifs-category-title"
            >
              {translate('win_gifs')}
            </h6>
            <img src="https://media2.giphy.com/media/4QFAH0qZ0LQnIwVYKT/giphy.gif" />
          </div>
          <div className="chat-gifs-category">
            <h6
              onClick={() => setCategory("loss")}
              className="chat-gifs-category-title"
            >
              {translate('loss_gifs')}
            </h6>
            <img src="https://media3.giphy.com/media/EXHHMS9caoxAA/giphy.gif" />
          </div>
        </div>
      )}
    </div>
  );
}
