import React from "react";

function ArrowBottomSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      fill="none"
      viewBox="0 0 16 28"
    >
      <path
        fill="#fff"
        d="M9 1a1 1 0 00-2 0h2zM7.293 27.707a1 1 0 001.414 0l6.364-6.364a1 1 0 00-1.414-1.414L8 25.586l-5.657-5.657A1 1 0 00.93 21.343l6.364 6.364zM7 1v26h2V1H7z"
      ></path>
    </svg>
  );
}

export default ArrowBottomSvg;
