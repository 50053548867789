import * as React from "react";

function WaitingSvg(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.727 4.364v-2.87A6.546 6.546 0 113.38 3.363l-1.026-1.03A8 8 0 108 0h-.727v4.364h1.454zM8 6.545c-.13 0-.257.018-.377.05L4.878 3.849 3.849 4.878l2.746 2.745A1.456 1.456 0 008 9.455a1.455 1.455 0 000-2.91z"
        fill="#fff"
      />
    </svg>
  );
}

export default WaitingSvg;
