import React from "react";
import menuIcons from "../model/menu-icons";
import ArrowRightSvg from "../components/svg/arrow-right-svg";
import { Link } from "react-router-dom";

const MenuItem = (props) => {
  return (
    <div onClick={() => window.setMenuActive(false)}>
      <Link to={props?.linkTo ? props.linkTo : "/"} className="menu-item">
        <div className="menu-image-holder">
          <img src={menuIcons[props.image]} alt="" />
        </div>
        <div className="menu-item-switch-holder">
          <h5 className="menu-item-title">{props.title}</h5>

          <button className="menu-item-button">
            <ArrowRightSvg />
          </button>
        </div>
      </Link>
    </div>
  );
};

export default MenuItem;
